import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUsersList } from "../api";
import { useSelector, useDispatch } from "react-redux";
import DotLoader from "../loader/DotLoader";
import "./style.css";

export default function UserList() {
  const { user } = useSelector((state) => state.auth);
  const { english } = useSelector((state) => state.language);
  const headers = { accountLanguage: english ? "EN" : "JA" };
  const navigate = useNavigate();
  if (!user.user_role === "super_admin") {
    navigate("/");
  }
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const init = () => {
    setLoading(true);
    getUsersList(headers)
      .then((res) => {
        setLoading(false);
        setUsers(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    init();
  }, [english]);

  console.log(users, "all user");
  return (
    <div className="all__user__list__component">
      {loading && <DotLoader />}
      <div className="all__user__list__component__title">
        <h5>List of Company & Startup users</h5>
      </div>
      <ul style={{ paddingTop: "50px" }}>
        {users.map((x, i) => (
          <Link to={`/en/chat/${x.userEmail}`}>
            <li className="all__user__list__component__list">
              {/* <div className="all__user__list__component__list__div"> */}
              <p>{i + 1}.</p>
              <img
                src={x.accountLogo}
                style={{ width: "30px", height: "30px" }}
              />
              <div className="all__user__list__component__list__name">
                <p>{x.userName}</p>
                <p style={{ fontSize: "700" }}>{x.organizationName}</p>
              </div>
              <p>{x.organizationType}</p>
              {/* </div> */}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
}
