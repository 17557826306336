import React, { useState } from "react";
import "./startupDetail.css";
import { useTranslation } from "react-i18next";
import Member from "./Member";

const Basic = ({ startup, members }) => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();

  const genders =
    startup?.targets.gender_n_others && startup?.targets.gender_n_others[1];
  return (
    <div className="basic__info">
      <h1 className="basic__info__title">{t("BasicInformations")}</h1>
      <hr />
      <div className="basic__info__informations">
        <div className="basic__info__informations__default">
          <div className="basic__info__informations__default__left">
            <div className="main-field">
              <p className="main-field__static__Field">{t("Company")}</p>
              <span className="main-field__dynamic_field">
                {startup?.company_name}
              </span>
            </div>

            <div className="main-field">
              <p className="main-field__static__Field">{t("Service")}</p>
              <div>
                {startup?.service_title.map((title, i) => (
                  <div>
                    <span
                      className="main-field__dynamic_field"
                      style={{
                        display: "flex",
                        // paddingRight: "2px",
                        // justifyContent: "flex-start",
                      }}
                    >
                      {title}
                      {i == startup?.service_title.length - 1 ? "" : ","}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="main-field">
              <p className="main-field__static__Field">{t("Logo")}</p>
              <img
                src={startup?.logo}
                style={{ width: "60px", height: "60px" }}
              />
            </div>
            {/* <div className="main-field" style={{ paddingTop: "20px" }}>
              <p className="main-field__static__Field">{t("Stage")}</p>
              <div>
                <p style={{ margin: 0 }} className="main-field__dynamic_field">
                  {t("StartupStage")}
                </p>
                <ul className="main-field__dynamic_ul">
                  <li className="ul__li">
                    <svg
                      width="36"
                      height="9"
                      viewBox="0 0 36 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 4.5C0 2.01472 2.01472 0 4.5 0H36V9H4.5C2.01472 9 0 6.98528 0 4.5Z"
                        fill="url(#paint0_linear_4613_1462)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4613_1462"
                          x1="-9.46662e-08"
                          y1="5"
                          x2="7.97232"
                          y2="19.9481"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#0094BB" />
                          <stop offset="1" stop-color="#1399B2" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </li>
                  <li className="ul__li">
                    <svg
                      width="36"
                      height="9"
                      viewBox="0 0 36 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="36" height="9" fill="#E6E6E6" />
                    </svg>
                  </li>
                  <li className="ul__li">
                    <svg
                      width="36"
                      height="9"
                      viewBox="0 0 36 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="36" height="9" fill="#E6E6E6" />
                    </svg>
                  </li>
                  <li className="ul__li">
                    <svg
                      width="36"
                      height="9"
                      viewBox="0 0 36 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 0H31.5C33.9853 0 36 2.01472 36 4.5C36 6.98528 33.9853 9 31.5 9H0V0Z"
                        fill="#E6E6E6"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
            </div> */}

            {/* <div className="main-field" style={{ paddingTop: "14px" }}>
              <p className="main-field__static__Field">{t("Round")}</p>
              <div>
                <p style={{ margin: 0 }} className="main-field__dynamic_field">
                  {t("StartupRound")}
                </p>
                <ul className="main-field__dynamic_ul">
                  <li className="ul__li">
                    <svg
                      width="36"
                      height="9"
                      viewBox="0 0 36 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 4.5C0 2.01472 2.01472 0 4.5 0H36V9H4.5C2.01472 9 0 6.98528 0 4.5Z"
                        fill="url(#paint0_linear_4613_1462)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4613_1462"
                          x1="-9.46662e-08"
                          y1="5"
                          x2="7.97232"
                          y2="19.9481"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#0094BB" />
                          <stop offset="1" stop-color="#1399B2" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </li>
                  <li className="ul__li">
                    <svg
                      width="36"
                      height="9"
                      viewBox="0 0 36 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="36"
                        height="9"
                        fill="url(#paint0_linear_4613_1479)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4613_1479"
                          x1="-9.46662e-08"
                          y1="5"
                          x2="7.97232"
                          y2="19.9481"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#139AB2" />
                          <stop offset="1" stop-color="#269FA9" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </li>
                  <li className="ul__li">
                    <svg
                      width="36"
                      height="9"
                      viewBox="0 0 36 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="36" height="9" fill="#E6E6E6" />
                    </svg>
                  </li>
                  <li className="ul__li">
                    <svg
                      width="36"
                      height="9"
                      viewBox="0 0 36 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 0H31.5C33.9853 0 36 2.01472 36 4.5C36 6.98528 33.9853 9 31.5 9H0V0Z"
                        fill="#E6E6E6"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="main-field" style={{ paddingTop: "20px" }}>
              <p className="main-field__static__Field">{t("Industry")}</p>
              <div>
                <ul
                  className="main-field__dynamic_ul"
                  style={{ display: "flex" }}
                >
                  <ul className="main-field__dynamic_ul">
                    {/* {startup?.industries.slice(0, 1).map((tag) => ( */}
                    {startup?.industries.map((tag) => (
                      <li
                        className="ul__li__div"
                        style={{ paddingRight: "10px" }}
                      >
                        <div className="ul__li__box">
                          <p className="ul__li__box_text">{tag}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </ul>
              </div>
            </div>

            <div className="main-field">
              <p className="main-field__static__Field">{t("BusinessModel")}</p>
              <div>
                <ul className="main-field__dynamic_ul">
                  {startup?.business_model.map((model) => (
                    <li
                      className="ul__li__div"
                      style={{ paddingRight: "10px" }}
                    >
                      <div className="ul__li__box">
                        <p className="ul__li__box_text">{model}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="basic__info__informations__default__right">
            <div className="main-field">
              <p className="main-field__static__Field">{t("Founder")}</p>
              <ul style={{ display: "flex", flexWrap: "wrap" }}>
                {startup?.founders.map((x, i) => (
                  <li
                    className="main-field__dynamic_field"
                    style={{ listStyle: "none" }}
                  >
                    {x}
                    {i == startup?.founders.length - 1 ? "" : ","}
                  </li>
                ))}
              </ul>
            </div>
            <div className="main-field">
              <p className="main-field__static__Field">{t("Address")}</p>
              <span className="main-field__dynamic_field">
                {startup?.address}
              </span>
            </div>
            <div className="main-field">
              <p className="main-field__static__Field">Web</p>
              <span
                className="main-field__dynamic_field"
                style={{ wordBreak: "break-all" }}
              >
                {startup?.web}
              </span>
            </div>
            <div className="main-field">
              <p className="main-field__static__Field">SNS/Web</p>
              <div>
                <ul className="main-field__dynamic_ul">
                  <a
                    href={`https://www.facebook.com/${
                      startup && startup.social_links.facebook
                    }`}
                    target="_blank"
                  >
                    <li
                      className="ul__li__div"
                      style={{ paddingRight: "10px" }}
                    >
                      <svg
                        width="21.67"
                        height="21.67"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ul__li__div__svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.333984 12.0652C0.333984 17.8654 4.54662 22.6886 10.0562 23.6667V15.2404H7.13954V12H10.0562V9.40709C10.0562 6.49043 11.9355 4.8707 14.5936 4.8707C15.4355 4.8707 16.3436 5.00001 17.1855 5.12932V8.11112H15.6951C14.2688 8.11112 13.9451 8.82376 13.9451 9.73182V12H17.0562L16.538 15.2404H13.9451V23.6667C19.4547 22.6886 23.6673 17.8664 23.6673 12.0652C23.6673 5.61251 18.4173 0.333344 12.0007 0.333344C5.58398 0.333344 0.333984 5.61251 0.333984 12.0652Z"
                          fill="#C8C8C8"
                        />
                      </svg>
                    </li>
                  </a>
                  <a
                    href={`https://www.twitter.com/${
                      startup && startup.social_links.twitter
                    }`}
                    target="_blank"
                  >
                    <li className="ul__li__div">
                      <svg
                        width="22.67"
                        height="20"
                        viewBox="0 0 24 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.6673 2.89738C22.8085 3.28775 21.8827 3.54428 20.9235 3.66697C21.9051 3.07583 22.6635 2.13893 23.0204 1.01242C22.0947 1.5701 21.0685 1.96047 19.9866 2.18355C19.1055 1.22434 17.8674 0.666656 16.4621 0.666656C13.841 0.666656 11.6995 2.80815 11.6995 5.45155C11.6995 5.83077 11.7441 6.19884 11.8222 6.5446C7.85151 6.34384 4.31582 4.43657 1.96241 1.54779C1.54973 2.25047 1.3155 3.07583 1.3155 3.94581C1.3155 5.6077 2.15202 7.07998 3.44584 7.9165C2.65393 7.9165 1.9178 7.69342 1.27089 7.35882V7.39228C1.27089 9.71222 2.92162 11.653 5.10773 12.0879C4.40586 12.28 3.66902 12.3067 2.95508 12.166C3.25802 13.1168 3.85131 13.9488 4.65156 14.545C5.45181 15.1412 6.41877 15.4716 7.41652 15.4898C5.72522 16.8287 3.62875 17.5524 1.47165 17.542C1.09243 17.542 0.713207 17.5197 0.333984 17.4751C2.45317 18.8359 4.97388 19.6278 7.67305 19.6278C16.4621 19.6278 21.2916 12.3333 21.2916 6.00923C21.2916 5.79731 21.2916 5.59655 21.2804 5.38463C22.2174 4.71541 23.0204 3.86774 23.6673 2.89738Z"
                          fill="#C8C8C8"
                        />
                      </svg>
                    </li>
                  </a>
                  <a
                    href={`https://www.linkedin.com/${
                      startup && startup.social_links.linkedin
                    }`}
                    target="_blank"
                  >
                    <li className="ul__li__div">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.864 0.166626H2.13669C1.04843 0.166626 0.166992 1.04807 0.166992 2.13632V19.8636C0.166992 20.9519 1.04843 21.8333 2.13669 21.8333H19.864C20.9522 21.8333 21.8337 20.9519 21.8337 19.8636V2.13632C21.8337 1.04807 20.9522 0.166626 19.864 0.166626ZM7.01563 17.8939H4.11033V8.54572H7.01563V17.8939ZM5.53343 7.20928C4.59684 7.20928 3.83949 6.44996 3.83949 5.51534C3.83949 4.58072 4.59783 3.82238 5.53343 3.82238C6.46707 3.82238 7.22639 4.5817 7.22639 5.51534C7.22639 6.44996 6.46707 7.20928 5.53343 7.20928ZM17.8982 17.8939H14.9949V13.3478C14.9949 12.2635 14.9752 10.869 13.4851 10.869C11.9734 10.869 11.7409 12.0498 11.7409 13.2691V17.8939H8.8376V8.54572H11.6247V9.82307H11.6641C12.0521 9.08837 12.9996 8.31329 14.4128 8.31329C17.3546 8.31329 17.8982 10.2495 17.8982 12.7668V17.8939Z"
                          fill="#838383"
                        />
                      </svg>
                    </li>
                  </a>
                  <a
                    href={`https://www.github.com/${
                      startup && startup.social_links.github
                    }`}
                    target="_blank"
                  >
                    <li className="ul__li__div">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.0003 0.166626C5.01762 0.166626 0.166992 5.01725 0.166992 11C0.166992 16.0763 3.66255 20.3238 8.37505 21.4993C8.32449 21.353 8.29199 21.1833 8.29199 20.9729V19.1213C7.85234 19.1213 7.11567 19.1213 6.9306 19.1213C6.18942 19.1213 5.5304 18.8027 5.21081 18.2104C4.85602 17.5523 4.79463 16.5457 3.91533 15.93C3.65442 15.7251 3.85303 15.4913 4.15366 15.5229C4.70887 15.68 5.16928 16.0609 5.60262 16.6261C6.03415 17.1921 6.23727 17.3203 7.04345 17.3203C7.43435 17.3203 8.01935 17.2977 8.57005 17.2111C8.86616 16.4591 9.37803 15.7666 10.0037 15.4398C6.39616 15.0688 4.67456 13.2741 4.67456 10.8375C4.67456 9.78843 5.12144 8.77371 5.88067 7.91878C5.63151 7.07017 5.31824 5.33954 5.97637 4.68051C7.59956 4.68051 8.58088 5.73315 8.81651 6.01753C9.6254 5.74038 10.5137 5.58329 11.4472 5.58329C12.3825 5.58329 13.2744 5.74038 14.0851 6.01933C14.318 5.73676 15.3003 4.68051 16.9271 4.68051C17.5879 5.34045 17.271 7.07829 17.0191 7.9251C17.7739 8.77822 18.218 9.79024 18.218 10.8375C18.218 13.2723 16.4991 15.0661 12.8971 15.4389C13.8883 15.9562 14.6114 17.4097 14.6114 18.5048V20.9729C14.6114 21.0668 14.5907 21.1345 14.5798 21.2149C18.8012 19.7352 21.8337 15.7269 21.8337 11C21.8337 5.01725 16.983 0.166626 11.0003 0.166626Z"
                          fill="#838383"
                        />
                      </svg>
                    </li>
                  </a>
                  {/* <li className="ul__li__div">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0003 0.166626C5.02686 0.166626 0.166992 5.0265 0.166992 11C0.166992 16.9734 5.02686 21.8333 11.0003 21.8333C16.9738 21.8333 21.8337 16.9734 21.8337 11C21.8337 5.0265 16.9738 0.166626 11.0003 0.166626ZM11.0003 1.79163C13.2441 1.79163 15.2955 2.59131 16.891 3.91915C15.3999 5.2318 13.695 6.29999 11.8562 7.11943C10.8982 5.37724 9.8583 3.68787 8.69084 2.0942C9.4298 1.9038 10.2009 1.79163 11.0003 1.79163ZM7.09229 2.6676C8.29066 4.25227 9.34804 5.9431 10.3211 7.6886C8.31933 8.38405 6.19992 8.83329 3.95866 8.83329C3.32618 8.83329 2.70272 8.79113 2.08398 8.73067C2.76532 6.03573 4.62542 3.82368 7.09229 2.6676ZM18.0431 5.06702C19.3751 6.64763 20.1835 8.68259 20.2044 10.9122C19.0347 10.6187 17.8123 10.4583 16.5524 10.4583C15.5622 10.4583 14.6055 10.5861 13.6685 10.7693C13.3421 10.0132 12.9846 9.27844 12.6169 8.54553C14.599 7.64916 16.4389 6.49125 18.0431 5.06702ZM11.0966 9.14221C11.4398 9.82001 11.7858 10.4929 12.0921 11.1914C8.80832 12.2185 5.98951 14.3034 4.09831 17.0874C2.66698 15.465 1.79199 13.3396 1.79199 11C1.79199 10.7755 1.81015 10.556 1.82585 10.3356C2.52746 10.4075 3.23599 10.4583 3.95866 10.4583C6.47382 10.4583 8.85809 9.95089 11.0966 9.14221ZM16.5524 12.0833C17.7705 12.0833 18.9457 12.2563 20.0669 12.5615C19.6418 15.0567 18.224 17.2061 16.2244 18.5844C15.7428 16.4518 15.0686 14.3957 14.2969 12.388C15.0412 12.2598 15.7708 12.0833 16.5524 12.0833ZM12.675 12.6927C13.5259 14.8597 14.2537 17.0891 14.7412 19.4127C13.598 19.9204 12.3344 20.2083 11.0003 20.2083C8.83944 20.2083 6.86043 19.4622 5.29167 18.2215C6.9968 15.5919 9.60866 13.6222 12.675 12.6927Z"
                        fill="#838383"
                      />
                    </svg>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="main-field">
              <p className="main-field__static__Field">{t("Capital")}</p>
              <span className="main-field__dynamic_field">
                {startup?.capital_amount}
              </span>
            </div>

            <div className="main-field">
              <p className="main-field__static__Field">{t("Team")}</p>
              <span className="main-field__dynamic_field">{startup?.team}</span>
            </div>

            <div className="main-field">
              <p className="main-field__static__Field">{t("Launch")}</p>
              <span className="main-field__dynamic_field">
                {startup?.launched_at}
              </span>
            </div>
          </div>
        </div>
        <div
          className="basic__info__informations__more"
          style={{ display: showMore ? "" : "none" }}
        >
          <div className="main-field">
            <p className="main-field__static__Field">{t("Strength")}</p>
            <div>
              <ul className="main-field__dynamic_ul">
                {startup &&
                  startup.strength.map((tag) => (
                    <li
                      className="ul__li__div"
                      style={{ paddingRight: "10px" }}
                    >
                      <div className="ul__li__box">
                        <p className="ul__li__box_text">{tag}</p>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="main-field">
            <p className="main-field__static__Field">{t("Target")}</p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {startup?.targets.regions.length > 0 && (
                <>
                  <p>{t("Region")}</p>
                  <ul className="main-field__dynamic_ul">
                    {startup?.targets.regions.map((tag) => (
                      <li
                        className="ul__li__div"
                        style={{ paddingRight: "10px" }}
                      >
                        <div className="ul__li__box">
                          <p className="ul__li__box_text">{tag}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {startup?.targets &&
              startup.targets?.gender_n_others[0]?.gender ? (
                <>
                  <p>{t("Gender")}</p>
                  <ul className="main-field__dynamic_ul">
                    {startup.targets?.gender_n_others[0].gender?.map(
                      (gender) => (
                        <li
                          className="ul__li__div"
                          style={{ paddingRight: "10px" }}
                        >
                          <div className="ul__li__box">
                            <p className="ul__li__box_text">{gender}</p>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </>
              ) : (
                ""
              )}
              {startup?.targets &&
              startup.targets?.gender_n_others[0]?.others ? (
                <>
                  <p>{t("Other")}</p>
                  <ul className="main-field__dynamic_ul">
                    {startup.targets?.gender_n_others[0].others?.map(
                      (gender) => (
                        <li
                          className="ul__li__div"
                          style={{ paddingRight: "10px" }}
                        >
                          <div className="ul__li__box">
                            <p className="ul__li__box_text">{gender}</p>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </>
              ) : (
                ""
              )}
              {/* <p>{t("Other")}</p>
              <ul className="main-field__dynamic_ul">
                {startup?.targets &&
                  startup.targets?.gender_n_others.length > 0 &&
                  startup.targets?.gender_n_others[0].other.map((gender) => (
                    <li
                      className="ul__li__div"
                      style={{ paddingRight: "10px" }}
                    >
                      <div className="ul__li__box">
                        <p className="ul__li__box_text">{gender}</p>
                      </div>
                    </li>
                  ))}
              </ul> */}
            </div>
          </div>
        </div>
        <div className="basic__info__toggle">
          <a onClick={() => setShowMore(!showMore)}>
            <p
              className="basic__info__toggle__button"
              style={{ display: "inline", textDecoration: "underline" }}
            >
              {" "}
              {t("Togglebutton")}
            </p>
            {showMore ? (
              <svg
                width="9"
                height="6"
                viewBox="0 0 9 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.87627 5.25368L4.78627 2.34368L7.69627 5.25368C7.98877 5.54618 8.46127 5.54618 8.75377 5.25368C9.04627 4.96118 9.04627 4.48868 8.75377 4.19618L5.31127 0.753677C5.01877 0.461177 4.54627 0.461177 4.25377 0.753677L0.81127 4.19618C0.51877 4.48868 0.51877 4.96118 0.81127 5.25368C1.10377 5.53868 1.58377 5.54618 1.87627 5.25368Z"
                  fill="#3C3C3C"
                />
              </svg>
            ) : (
              <svg
                width="9"
                height="6"
                viewBox="0 0 9 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.18867 0.753769L4.27867 3.66377L1.36867 0.753769C1.07617 0.461269 0.603672 0.461269 0.311172 0.753769C0.0186719 1.04627 0.0186719 1.51877 0.311172 1.81127L3.75367 5.25377C4.04617 5.54627 4.51867 5.54627 4.81117 5.25377L8.25367 1.81127C8.54617 1.51877 8.54617 1.04627 8.25367 0.753769C7.96117 0.468769 7.48117 0.461269 7.18867 0.753769Z"
                  fill="#3C3C3C"
                />
              </svg>
            )}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Basic;
