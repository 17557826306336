import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isEnable: false,
};

export const hamSlice = createSlice({
  name: "ham",
  initialState,
  reducers: {
    toggle: (state) => {
      state.isEnable = !state.isEnable;
    },
  },
});

export const { toggle } = hamSlice.actions;
export default hamSlice.reducer;
