import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./message.css";

import wordFile from "../../asset/images/word.png";
import excelFile from "../../asset/images/excel.png";
import pdfFile from "../../asset/images/pdf.png";
import moment from "moment";
import ApplicationMessage from "./ApplicationMessage";

const Message = ({
  messages,
  sameSender,
  prev,
  receiverOrganization,
  otherUser,
}) => {
  const [senderInfo, setSenderInfo] = useState({});
  const [translate, setTranslate] = useState(false);
  const { user } = useSelector((state) => state.auth);

  // console.log(messages, "messages");
  // console.log(prev, "prev");
  const newDate =
    moment.utc(messages.createdAt).local().format("L") !=
    moment.utc(prev?.createdAt).local().format("L");
  return (
    <>
      {messages.status === "PARSABLE" ? (
        <ApplicationMessage
          messages={messages}
          sameSender={sameSender}
          otherUser={otherUser}
        />
      ) : (
        <>
          {!sameSender ? (
            <div className="message">
              <div>
                {messages.status === "UNREAD" &&
                messages.senderEmail != otherUser.email ? (
                  <div className="messages__unread__notice">
                    <hr style={{ border: "1px solid #D7A3A4" }} />
                    <div className="messages__unread__notice__red__unread">
                      <p className="messages__unread__notice__red__unread__text">
                        Unread
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {newDate ? (
                <div className="message__bottom">
                  {moment.utc(messages.createdAt).local().format("L")}
                </div>
              ) : (
                ""
              )}

              <div className="message__top">
                <img
                  className="message__top__image"
                  src={messages.senderLogo}
                />
                <div className="message__top__right">
                  <p className="message__top__right__name">
                    {messages.senderName}
                  </p>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="message__top__right__company">
                      {otherUser.email === messages.senderEmail
                        ? otherUser.organization.name
                        : receiverOrganization}
                    </p>
                    <p className="message__top__right_time">
                      {moment.utc(messages.createdAt).local().format("h:mm a")}
                    </p>
                  </div>
                  {translate ? (
                    <div className="message__top__right__message__box">
                      <p className="message__top__right__message__box__message">
                        {otherUser.email === messages.senderEmail
                          ? messages.translatedText
                          : messages.originalText}
                      </p>

                      <div
                        className="message__top__right__message__box__message__translate"
                        onClick={() => setTranslate(!translate)}
                      >
                        <svg
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginTop: "2px" }}
                        >
                          <path
                            d="M7.01422 8.04518C7.09149 7.84648 7.04182 7.62018 6.88727 7.46563L5.73369 6.32861L5.75025 6.31205C6.71065 5.24126 7.39507 4.01041 7.79799 2.70781H8.86878C9.16683 2.70781 9.41521 2.45943 9.41521 2.16137V2.15034C9.41521 1.85228 9.16683 1.6039 8.86878 1.6039H5.55155V1.05195C5.55155 0.748378 5.30317 0.5 4.9996 0.5C4.69602 0.5 4.44765 0.748378 4.44765 1.05195V1.6039H1.13042C0.832363 1.6039 0.583984 1.85228 0.583984 2.15034C0.583984 2.45391 0.832363 2.69677 1.13042 2.69677H6.74928C6.37948 3.76755 5.79441 4.77763 4.9996 5.66075C4.55252 5.16951 4.17719 4.63412 3.86258 4.07113C3.77426 3.91106 3.6142 3.81171 3.43205 3.81171C3.05121 3.81171 2.80835 4.22567 2.99601 4.55685C3.34374 5.18055 3.76875 5.77666 4.2655 6.32861L1.85347 8.70752C1.63269 8.92278 1.63269 9.27603 1.85347 9.49129C2.06873 9.70656 2.41646 9.70656 2.63724 9.49129L4.9996 7.12342L6.11454 8.23836C6.39604 8.51986 6.87623 8.41499 7.01422 8.04518ZM9.69119 4.91561C9.36002 4.91561 9.06196 5.11984 8.94605 5.43445L6.92039 10.8436C6.78792 11.1803 7.04182 11.539 7.40059 11.539C7.61585 11.539 7.80903 11.4066 7.8863 11.2023L8.37754 9.88318H10.9993L11.4961 11.2023C11.5733 11.401 11.7665 11.539 11.9818 11.539C12.3406 11.539 12.5945 11.1803 12.4675 10.8436L10.4418 5.43445C10.3204 5.11984 10.0224 4.91561 9.69119 4.91561ZM8.79702 8.77928L9.69119 6.38932L10.5853 8.77928H8.79702Z"
                            fill="#0094BB"
                          />
                        </svg>
                        <p className="message__top__right__message__box__message__translate__text">
                          {otherUser.email === messages.senderEmail
                            ? !translate
                              ? "View Translated Text"
                              : "View Original Text"
                            : otherUser.email != messages.senderEmail
                            ? !translate
                              ? "View Original Text"
                              : "View Translated Text"
                            : "none"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="message__top__right__message__box">
                      <p className="message__top__right__message__box__message">
                        {otherUser.email === messages.senderEmail
                          ? messages.originalText
                          : messages.translatedText}
                      </p>
                      <div
                        className="message__top__right__message__box__message__translate"
                        onClick={() => setTranslate(!translate)}
                      >
                        <svg
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginTop: "2px" }}
                        >
                          <path
                            d="M7.01422 8.04518C7.09149 7.84648 7.04182 7.62018 6.88727 7.46563L5.73369 6.32861L5.75025 6.31205C6.71065 5.24126 7.39507 4.01041 7.79799 2.70781H8.86878C9.16683 2.70781 9.41521 2.45943 9.41521 2.16137V2.15034C9.41521 1.85228 9.16683 1.6039 8.86878 1.6039H5.55155V1.05195C5.55155 0.748378 5.30317 0.5 4.9996 0.5C4.69602 0.5 4.44765 0.748378 4.44765 1.05195V1.6039H1.13042C0.832363 1.6039 0.583984 1.85228 0.583984 2.15034C0.583984 2.45391 0.832363 2.69677 1.13042 2.69677H6.74928C6.37948 3.76755 5.79441 4.77763 4.9996 5.66075C4.55252 5.16951 4.17719 4.63412 3.86258 4.07113C3.77426 3.91106 3.6142 3.81171 3.43205 3.81171C3.05121 3.81171 2.80835 4.22567 2.99601 4.55685C3.34374 5.18055 3.76875 5.77666 4.2655 6.32861L1.85347 8.70752C1.63269 8.92278 1.63269 9.27603 1.85347 9.49129C2.06873 9.70656 2.41646 9.70656 2.63724 9.49129L4.9996 7.12342L6.11454 8.23836C6.39604 8.51986 6.87623 8.41499 7.01422 8.04518ZM9.69119 4.91561C9.36002 4.91561 9.06196 5.11984 8.94605 5.43445L6.92039 10.8436C6.78792 11.1803 7.04182 11.539 7.40059 11.539C7.61585 11.539 7.80903 11.4066 7.8863 11.2023L8.37754 9.88318H10.9993L11.4961 11.2023C11.5733 11.401 11.7665 11.539 11.9818 11.539C12.3406 11.539 12.5945 11.1803 12.4675 10.8436L10.4418 5.43445C10.3204 5.11984 10.0224 4.91561 9.69119 4.91561ZM8.79702 8.77928L9.69119 6.38932L10.5853 8.77928H8.79702Z"
                            fill="#0094BB"
                          />
                        </svg>
                        <p className="message__top__right__message__box__message__translate__text">
                          {otherUser.email === messages.senderEmail
                            ? !translate
                              ? "View Translated Text"
                              : "View Original Text"
                            : otherUser.email != messages.senderEmail
                            ? !translate
                              ? "View Original Text"
                              : "View Translated Text"
                            : "none"}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  position: "relative",
                  margin: messages.contents
                    ? "8px 60px 0px 60px"
                    : "0 !important",
                  display: messages.contents ? "" : "none",
                }}
                className="message__attachment__show"
              >
                {messages.contents &&
                  messages.contents.map((x, i) => (
                    <div
                      className="message__attachment__show__one"
                      style={{
                        padding:
                          x.contentName.split(".")[1] == "png" ||
                          x.contentName.split(".")[1] == "jpeg" ||
                          x.contentName.split(".")[1] == "jpg"
                            ? "0"
                            : "10px",
                        border:
                          x.contentName.split(".")[1] == "png" ||
                          x.contentName.split(".")[1] == "jpeg" ||
                          x.contentName.split(".")[1] == "jpg"
                            ? "none"
                            : "1px solid #e6e6e6",
                        margin: "20px 0",
                      }}
                    >
                      {x.contentName.split(".")[1] == "png" ||
                      x.contentName.split(".")[1] == "jpeg" ||
                      x.contentName.split(".")[1] == "jpg" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "240px",
                            maxHeight: "160px",
                          }}
                        >
                          <img
                            src={x.contentUrl}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "6px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "5px 0 0 0",
                            }}
                          >
                            <p className="message__attachment__image__name">
                              {x.contentName}
                            </p>
                            <a
                              className="profile__page__messanger__chat__area__attachment__show__one__download"
                              style={{ margin: "0" }}
                              href={x.contentUrl}
                            >
                              download
                            </a>
                          </div>
                        </div>
                      ) : (
                        <>
                          <img
                            src={
                              x.contentName.split(".")[1] === "docx"
                                ? wordFile
                                : x.contentName.split(".")[1] === "pdf"
                                ? pdfFile
                                : excelFile
                            }
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p className="profile__page__messanger__chat__area__attachment__show__one__name">
                              {x.contentName}
                            </p>
                            <a
                              className="profile__page__messanger__chat__area__attachment__show__one__download"
                              style={{ margin: "0" }}
                              href={x.contentUrl}
                            >
                              download
                            </a>
                          </div>
                        </>
                      )}

                      <span
                        className="profile__page__messanger__chat__area__attachment__show__one__close__btn"
                        style={{
                          position: "absolute",
                          top: "-8%",
                          right: "-6%",
                          fontSize: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="8" fill="#B4B4B4" />
                          <path
                            d="M11.8225 4.18354C11.5859 3.94691 11.2036 3.94691 10.967 4.18354L8 7.14448L5.03299 4.17747C4.79636 3.94084 4.41411 3.94084 4.17747 4.17747C3.94084 4.41411 3.94084 4.79636 4.17747 5.03299L7.14448 8L4.17747 10.967C3.94084 11.2036 3.94084 11.5859 4.17747 11.8225C4.41411 12.0592 4.79636 12.0592 5.03299 11.8225L8 8.85552L10.967 11.8225C11.2036 12.0592 11.5859 12.0592 11.8225 11.8225C12.0592 11.5859 12.0592 11.2036 11.8225 10.967L8.85552 8L11.8225 5.03299C12.0531 4.80243 12.0531 4.41411 11.8225 4.18354Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className="message" style={{ marginTop: "0" }}>
              {newDate ? (
                <div className="message__bottom">
                  {moment.utc(messages.createdAt).local().format("L")}
                </div>
              ) : (
                ""
              )}
              <div
                className="message__top"
                style={{
                  paddingTop: !newDate && "10px",
                  paddingLeft: !newDate && "60px",
                }}
              >
                {newDate && (
                  <img
                    className="message__top__image"
                    src={messages.senderLogo}
                  />
                )}
                <div className="message__top__right">
                  {newDate && (
                    <>
                      <p className="message__top__right__name">
                        {messages.senderName}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="message__top__right__company">
                          {otherUser.email === messages.senderEmail
                            ? otherUser.organization.name
                            : receiverOrganization}
                        </p>
                        <p className="message__top__right_time">
                          {moment
                            .utc(messages.createdAt)
                            .local()
                            .format("h:mm a")}
                        </p>
                      </div>
                    </>
                  )}

                  {translate ? (
                    <div className="message__top__right__message__box">
                      <p className="message__top__right__message__box__message">
                        {otherUser.email === messages.senderEmail
                          ? messages.translatedText
                          : messages.originalText}
                      </p>
                      <div
                        className="message__top__right__message__box__message__translate"
                        onClick={() => setTranslate(!translate)}
                      >
                        <svg
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginTop: "2px" }}
                        >
                          <path
                            d="M7.01422 8.04518C7.09149 7.84648 7.04182 7.62018 6.88727 7.46563L5.73369 6.32861L5.75025 6.31205C6.71065 5.24126 7.39507 4.01041 7.79799 2.70781H8.86878C9.16683 2.70781 9.41521 2.45943 9.41521 2.16137V2.15034C9.41521 1.85228 9.16683 1.6039 8.86878 1.6039H5.55155V1.05195C5.55155 0.748378 5.30317 0.5 4.9996 0.5C4.69602 0.5 4.44765 0.748378 4.44765 1.05195V1.6039H1.13042C0.832363 1.6039 0.583984 1.85228 0.583984 2.15034C0.583984 2.45391 0.832363 2.69677 1.13042 2.69677H6.74928C6.37948 3.76755 5.79441 4.77763 4.9996 5.66075C4.55252 5.16951 4.17719 4.63412 3.86258 4.07113C3.77426 3.91106 3.6142 3.81171 3.43205 3.81171C3.05121 3.81171 2.80835 4.22567 2.99601 4.55685C3.34374 5.18055 3.76875 5.77666 4.2655 6.32861L1.85347 8.70752C1.63269 8.92278 1.63269 9.27603 1.85347 9.49129C2.06873 9.70656 2.41646 9.70656 2.63724 9.49129L4.9996 7.12342L6.11454 8.23836C6.39604 8.51986 6.87623 8.41499 7.01422 8.04518ZM9.69119 4.91561C9.36002 4.91561 9.06196 5.11984 8.94605 5.43445L6.92039 10.8436C6.78792 11.1803 7.04182 11.539 7.40059 11.539C7.61585 11.539 7.80903 11.4066 7.8863 11.2023L8.37754 9.88318H10.9993L11.4961 11.2023C11.5733 11.401 11.7665 11.539 11.9818 11.539C12.3406 11.539 12.5945 11.1803 12.4675 10.8436L10.4418 5.43445C10.3204 5.11984 10.0224 4.91561 9.69119 4.91561ZM8.79702 8.77928L9.69119 6.38932L10.5853 8.77928H8.79702Z"
                            fill="#0094BB"
                          />
                        </svg>
                        <p className="message__top__right__message__box__message__translate__text">
                          {otherUser.email === messages.senderEmail
                            ? !translate
                              ? "View Translated Text"
                              : "View Original Text"
                            : otherUser.email != messages.senderEmail
                            ? !translate
                              ? "View Original Text"
                              : "View Translated Text"
                            : "none"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className="message__top__right__message__box"
                        // onClick={() => setTranslate(!translate)}
                      >
                        <p className="message__top__right__message__box__message">
                          {otherUser.email === messages.senderEmail
                            ? messages.originalText
                            : messages.translatedText}
                        </p>
                        <div
                          className="message__top__right__message__box__message__translate"
                          onClick={() => setTranslate(!translate)}
                        >
                          <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginTop: "2px" }}
                          >
                            <path
                              d="M7.01422 8.04518C7.09149 7.84648 7.04182 7.62018 6.88727 7.46563L5.73369 6.32861L5.75025 6.31205C6.71065 5.24126 7.39507 4.01041 7.79799 2.70781H8.86878C9.16683 2.70781 9.41521 2.45943 9.41521 2.16137V2.15034C9.41521 1.85228 9.16683 1.6039 8.86878 1.6039H5.55155V1.05195C5.55155 0.748378 5.30317 0.5 4.9996 0.5C4.69602 0.5 4.44765 0.748378 4.44765 1.05195V1.6039H1.13042C0.832363 1.6039 0.583984 1.85228 0.583984 2.15034C0.583984 2.45391 0.832363 2.69677 1.13042 2.69677H6.74928C6.37948 3.76755 5.79441 4.77763 4.9996 5.66075C4.55252 5.16951 4.17719 4.63412 3.86258 4.07113C3.77426 3.91106 3.6142 3.81171 3.43205 3.81171C3.05121 3.81171 2.80835 4.22567 2.99601 4.55685C3.34374 5.18055 3.76875 5.77666 4.2655 6.32861L1.85347 8.70752C1.63269 8.92278 1.63269 9.27603 1.85347 9.49129C2.06873 9.70656 2.41646 9.70656 2.63724 9.49129L4.9996 7.12342L6.11454 8.23836C6.39604 8.51986 6.87623 8.41499 7.01422 8.04518ZM9.69119 4.91561C9.36002 4.91561 9.06196 5.11984 8.94605 5.43445L6.92039 10.8436C6.78792 11.1803 7.04182 11.539 7.40059 11.539C7.61585 11.539 7.80903 11.4066 7.8863 11.2023L8.37754 9.88318H10.9993L11.4961 11.2023C11.5733 11.401 11.7665 11.539 11.9818 11.539C12.3406 11.539 12.5945 11.1803 12.4675 10.8436L10.4418 5.43445C10.3204 5.11984 10.0224 4.91561 9.69119 4.91561ZM8.79702 8.77928L9.69119 6.38932L10.5853 8.77928H8.79702Z"
                              fill="#0094BB"
                            />
                          </svg>
                          <p className="message__top__right__message__box__message__translate__text">
                            {otherUser.email === messages.senderEmail
                              ? !translate
                                ? "View Translated Text"
                                : "View Original Text"
                              : otherUser.email != messages.senderEmail
                              ? !translate
                                ? "View Original Text"
                                : "View Translated Text"
                              : "none"}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                style={{ position: "relative" }}
                className="message__attachment__show"
              >
                {messages.contents &&
                  messages.contents.map((x, i) => (
                    <div
                      className="message__attachment__show__one"
                      style={{
                        padding:
                          x.contentName.split(".")[1] == "png" ||
                          x.contentName.split(".")[1] == "jpeg"
                            ? "0"
                            : "10px",
                        border:
                          x.contentName.split(".")[1] == "png" ||
                          x.contentName.split(".")[1] == "jpeg"
                            ? "none"
                            : "1px solid #e6e6e6",
                      }}
                    >
                      {x.contentName.split(".")[1] == "png" ||
                      x.contentName.split(".")[1] == "jpeg" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={x.contentUrl}
                            style={{
                              maxWidth: "240px",
                              maxHeight: "160px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="message__attachment__image__name">
                              {x.contentName}
                            </p>
                            <a
                              className="profile__page__messanger__chat__area__attachment__show__one__download"
                              style={{ margin: "0" }}
                              href={x.contentUrl}
                            >
                              download
                            </a>
                          </div>
                        </div>
                      ) : (
                        <>
                          <img
                            src={
                              x.contentName.split(".")[1] === "docx"
                                ? wordFile
                                : x.contentName.split(".")[1] === "pdf"
                                ? pdfFile
                                : excelFile
                            }
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p className="profile__page__messanger__chat__area__attachment__show__one__name">
                              {x.contentName}
                            </p>
                            <a
                              className="profile__page__messanger__chat__area__attachment__show__one__download"
                              style={{ margin: "0" }}
                              href={x.contentUrl}
                            >
                              download
                            </a>
                          </div>
                        </>
                      )}

                      <span
                        className="profile__page__messanger__chat__area__attachment__show__one__close__btn"
                        style={{
                          position: "absolute",
                          top: "-8%",
                          right: "-6%",
                          fontSize: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="8" fill="#B4B4B4" />
                          <path
                            d="M11.8225 4.18354C11.5859 3.94691 11.2036 3.94691 10.967 4.18354L8 7.14448L5.03299 4.17747C4.79636 3.94084 4.41411 3.94084 4.17747 4.17747C3.94084 4.41411 3.94084 4.79636 4.17747 5.03299L7.14448 8L4.17747 10.967C3.94084 11.2036 3.94084 11.5859 4.17747 11.8225C4.41411 12.0592 4.79636 12.0592 5.03299 11.8225L8 8.85552L10.967 11.8225C11.2036 12.0592 11.5859 12.0592 11.8225 11.8225C12.0592 11.5859 12.0592 11.2036 11.8225 10.967L8.85552 8L11.8225 5.03299C12.0531 4.80243 12.0531 4.41411 11.8225 4.18354Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Message;
