import React, { useState, useEffect, useRef } from "react";
import "./startupDetail.css";
import Basic from "./Basic";
import Founder from "./Founder";
import Member from "./Member";
import Funding from "./Funding";
import { Link, useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import Loader from "../loader/Loader";
import { getStartupDetail } from "../api";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Autoplay, Thumbs } from "swiper";
import { useSelector, useDispatch } from "react-redux";
import DummyImage from "../../asset/images/Image.png";
import CoverDummyImage from "../../asset/images/detailPageCoverImage.png";
// swiper bundle styles
import "swiper/swiper-bundle.css";
import "swiper/swiper.scss";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/thumbs/thumbs.scss";

const StartupDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [startup, setStartup] = useState("");
  const [startups, setStartups] = useState("");
  const [loading, setLoading] = useState(false);
  const [introduction, setIntroduction] = useState("");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedImg, setSelectedImg] = useState(
    startup && startup.introduction.media?.about_images[activeIndex]
  );
  const [currentPic, setCurrentPic] = useState(0);
  const [activeSocialLink, setActiveSocialLink] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { english } = useSelector((state) => state.language);
  const headers = { accountLanguage: english ? "EN" : "JA" };
  const autoPlayRef = useRef();
  const valueRef = useRef(null);

  const initValue = async () => {
    setLoading(true);
    getStartupDetail(id, headers)
      .then((res) => {
        setLoading(false);
        console.log(res, "response from startup detail");
        res.data.data && setStartup(res.data.data);
        setIntroduction(res.data.data.introduction);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "err from startup");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    initValue();
  }, [english]);

  useEffect(() => {
    setTimeout(() => {
      setActiveSocialLink(false);
    }, 3000);
  }, [activeSocialLink]);
  let totalImage = startup && startup.introduction.media?.about_images.length;
  function snsLink(web) {
    window.open(web, "_blank").opener = null;
  }
  console.log(startup, "startup detail");
  return (
    <div className="page">
      <div className="startup__detail__page">
        <div className="startup__detail__page__for__big__screen__image__title__links">
          <div className="startup__detail__page__for__big__screen__title__links">
            {/* <div className="startup__detail__page__links">
              <div
                className={
                  activeSocialLink
                    ? "startup__detail__page__social__link" + " " + "active"
                    : "startup__detail__page__social__link"
                }
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
              >
                <input
                  type="text"
                  ref={valueRef}
                  value="ok"
                  style={{ display: "none" }}
                />
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="startup__detail__page__social__link__svg"
                  onClick={() =>
                    snsLink(
                      `http://www.facebook.com/share.php?u=${
                        startup && startup.social_links?.facebook
                      }`
                    )
                  }
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.333984 12.0652C0.333984 17.8654 4.54662 22.6886 10.0562 23.6667V15.2404H7.13954V12H10.0562V9.40709C10.0562 6.49043 11.9355 4.8707 14.5936 4.8707C15.4355 4.8707 16.3436 5.00001 17.1855 5.12932V8.11112H15.6951C14.2688 8.11112 13.9451 8.82376 13.9451 9.73182V12H17.0562L16.538 15.2404H13.9451V23.6667C19.4547 22.6886 23.6673 17.8664 23.6673 12.0652C23.6673 5.61251 18.4173 0.333344 12.0007 0.333344C5.58398 0.333344 0.333984 5.61251 0.333984 12.0652Z"
                    fill="#C8C8C8"
                  />
                </svg>
              </div>

              <div
                className="startup__detail__page__social__link"
                style={{ cursor: "pointer", width: "20px", height: "16.25px" }}
              >
                <svg
                  width="24"
                  height="20"
                  viewBox="0 0 24 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() =>
                    snsLink(
                      `http://twitter.com/share?url=${
                        startup && startup.social_links?.twitter
                      }`
                    )
                  }
                >
                  <path
                    d="M23.6673 2.89738C22.8085 3.28775 21.8827 3.54428 20.9235 3.66697C21.9051 3.07583 22.6635 2.13893 23.0204 1.01242C22.0947 1.5701 21.0685 1.96047 19.9866 2.18355C19.1055 1.22434 17.8674 0.666656 16.4621 0.666656C13.841 0.666656 11.6995 2.80815 11.6995 5.45155C11.6995 5.83077 11.7441 6.19884 11.8222 6.5446C7.85151 6.34384 4.31582 4.43657 1.96241 1.54779C1.54973 2.25047 1.3155 3.07583 1.3155 3.94581C1.3155 5.6077 2.15202 7.07998 3.44584 7.9165C2.65393 7.9165 1.9178 7.69342 1.27089 7.35882V7.39228C1.27089 9.71222 2.92162 11.653 5.10773 12.0879C4.40586 12.28 3.66902 12.3067 2.95508 12.166C3.25802 13.1168 3.85131 13.9488 4.65156 14.545C5.45181 15.1412 6.41877 15.4716 7.41652 15.4898C5.72522 16.8287 3.62875 17.5524 1.47165 17.542C1.09243 17.542 0.713207 17.5197 0.333984 17.4751C2.45317 18.8359 4.97388 19.6278 7.67305 19.6278C16.4621 19.6278 21.2916 12.3333 21.2916 6.00923C21.2916 5.79731 21.2916 5.59655 21.2804 5.38463C22.2174 4.71541 23.0204 3.86774 23.6673 2.89738Z"
                    fill="#C8C8C8"
                  />
                </svg>
              </div>
              <div
                className="startup__detail__page__social__link"
                style={{ cursor: "pointer", width: "22px", height: "11px" }}
              >
                <svg
                  width="26"
                  height="13"
                  viewBox="0 0 26 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => snsLink(startup && startup?.web)}
                >
                  <path
                    d="M2.60435 6.58332C2.60435 4.38882 4.38818 2.60499 6.58268 2.60499H11.716V0.166656H6.58268C3.04068 0.166656 0.166016 3.04132 0.166016 6.58332C0.166016 10.1253 3.04068 13 6.58268 13H11.716V10.5617H6.58268C4.38818 10.5617 2.60435 8.77782 2.60435 6.58332ZM7.86602 7.86666H18.1327V5.29999H7.86602V7.86666ZM19.416 0.166656H14.2827V2.60499H19.416C21.6105 2.60499 23.3944 4.38882 23.3944 6.58332C23.3944 8.77782 21.6105 10.5617 19.416 10.5617H14.2827V13H19.416C22.958 13 25.8327 10.1253 25.8327 6.58332C25.8327 3.04132 22.958 0.166656 19.416 0.166656Z"
                    fill="#C8C8C8"
                  />
                </svg>
              </div>
            </div> */}
            <div className="startup__detail__page__title__div">
              <h1>{startup && startup.introduction?.about_title}</h1>
            </div>
          </div>
          <div className="startup__detail__page__image__container">
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={0}
              slidesPerView={totalImage}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[Thumbs]}
              className="startup__swiper__thumb"
              style={{
                display: window.innerWidth < 700 ? "none" : "",
                margin: "0",
                width: "40px !important",
              }}
            >
              {startup.introduction?.media ? (
                startup.introduction?.media.about_images.map((image, i) => (
                  <SwiperSlide className="startup__swiper__thumb2">
                    <img
                      src={image}
                      className="company__detail__page__image__container__all__images__thumbnail"
                    />
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide className="startup__swiper__thumb2">
                  <img
                    src={CoverDummyImage}
                    className="company__detail__page__image__container__all__images__thumbnail"
                  />
                </SwiperSlide>
              )}
            </Swiper>
            <div className="startup__detail__page__image__container__show">
              <Swiper
                modules={[Autoplay, Thumbs]}
                thumbs={{ swiper: thumbsSwiper }}
                autoplay={true}
                spaceBetween={0}
                slidesPerView={1}
                className="mySwiper2"
              >
                {startup.introduction?.media ? (
                  startup.introduction?.media.about_images.map((pic, index) => (
                    <SwiperSlide>
                      <img
                        src={pic}
                        style={{ borderRadius: "30px" }}
                        className="mySwiper2__image"
                      />
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide>
                    <img
                      src={CoverDummyImage}
                      style={{ borderRadius: "30px" }}
                      className="mySwiper2__image"
                    />
                  </SwiperSlide>
                )}
              </Swiper>

              {/* <div className="startup__detail__page__links__mobile__version">
                <div
                  className={
                    activeSocialLink
                      ? "startup__detail__page__social__link" + " " + "active"
                      : "startup__detail__page__social__link"
                  }
                  style={{ cursor: "pointer", width: "20px", height: "20px" }}
                >
                  <input
                    type="text"
                    ref={valueRef}
                    value="ok"
                    style={{ display: "none" }}
                  />
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="startup__detail__page__social__link__svg"
                    onClick={() =>
                      snsLink(
                        `http://www.facebook.com/share.php?u=${
                          startup && startup.social_links?.facebook
                        }`
                      )
                    }
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.333984 12.0652C0.333984 17.8654 4.54662 22.6886 10.0562 23.6667V15.2404H7.13954V12H10.0562V9.40709C10.0562 6.49043 11.9355 4.8707 14.5936 4.8707C15.4355 4.8707 16.3436 5.00001 17.1855 5.12932V8.11112H15.6951C14.2688 8.11112 13.9451 8.82376 13.9451 9.73182V12H17.0562L16.538 15.2404H13.9451V23.6667C19.4547 22.6886 23.6673 17.8664 23.6673 12.0652C23.6673 5.61251 18.4173 0.333344 12.0007 0.333344C5.58398 0.333344 0.333984 5.61251 0.333984 12.0652Z"
                      fill="#C8C8C8"
                    />
                  </svg>
                </div>

                <div
                  className="startup__detail__page__social__link"
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    height: "16.25px",
                  }}
                >
                  <svg
                    width="24"
                    height="20"
                    viewBox="0 0 24 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() =>
                      snsLink(
                        `http://twitter.com/share?url=${
                          startup && startup.social_links?.twitter
                        }`
                      )
                    }
                    // onClick={() => clipBoard(startup.social_links?.twitter)}
                  >
                    <path
                      d="M23.6673 2.89738C22.8085 3.28775 21.8827 3.54428 20.9235 3.66697C21.9051 3.07583 22.6635 2.13893 23.0204 1.01242C22.0947 1.5701 21.0685 1.96047 19.9866 2.18355C19.1055 1.22434 17.8674 0.666656 16.4621 0.666656C13.841 0.666656 11.6995 2.80815 11.6995 5.45155C11.6995 5.83077 11.7441 6.19884 11.8222 6.5446C7.85151 6.34384 4.31582 4.43657 1.96241 1.54779C1.54973 2.25047 1.3155 3.07583 1.3155 3.94581C1.3155 5.6077 2.15202 7.07998 3.44584 7.9165C2.65393 7.9165 1.9178 7.69342 1.27089 7.35882V7.39228C1.27089 9.71222 2.92162 11.653 5.10773 12.0879C4.40586 12.28 3.66902 12.3067 2.95508 12.166C3.25802 13.1168 3.85131 13.9488 4.65156 14.545C5.45181 15.1412 6.41877 15.4716 7.41652 15.4898C5.72522 16.8287 3.62875 17.5524 1.47165 17.542C1.09243 17.542 0.713207 17.5197 0.333984 17.4751C2.45317 18.8359 4.97388 19.6278 7.67305 19.6278C16.4621 19.6278 21.2916 12.3333 21.2916 6.00923C21.2916 5.79731 21.2916 5.59655 21.2804 5.38463C22.2174 4.71541 23.0204 3.86774 23.6673 2.89738Z"
                      fill="#C8C8C8"
                    />
                  </svg>
                </div>
                <div
                  className="startup__detail__page__social__link"
                  style={{ cursor: "pointer", width: "22px", height: "11px" }}
                >
                  <svg
                    width="26"
                    height="13"
                    viewBox="0 0 26 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => snsLink(startup && startup?.web)}
                  >
                    <path
                      d="M2.60435 6.58332C2.60435 4.38882 4.38818 2.60499 6.58268 2.60499H11.716V0.166656H6.58268C3.04068 0.166656 0.166016 3.04132 0.166016 6.58332C0.166016 10.1253 3.04068 13 6.58268 13H11.716V10.5617H6.58268C4.38818 10.5617 2.60435 8.77782 2.60435 6.58332ZM7.86602 7.86666H18.1327V5.29999H7.86602V7.86666ZM19.416 0.166656H14.2827V2.60499H19.416C21.6105 2.60499 23.3944 4.38882 23.3944 6.58332C23.3944 8.77782 21.6105 10.5617 19.416 10.5617H14.2827V13H19.416C22.958 13 25.8327 10.1253 25.8327 6.58332C25.8327 3.04132 22.958 0.166656 19.416 0.166656Z"
                      fill="#C8C8C8"
                    />
                  </svg>
                </div>
              </div> */}
              {loading ? <Loader /> : ""}
              <Link
                // to={
                //   user && user.user_role === "super_user"
                //     ? `/${english ? "en" : "jp"}/chat/${
                //         startup && startup.founders[0].email
                //       }`
                //     : ""
                // }
                to={`/${english ? "en" : "jp"}/chat`}
                className="startup__detail__page__image__container__show__contact__text"
                style={{ cursor: "default" }}
              >
                <button className="startup__detail__page__image__container__show__contact__btn">
                  {t("ContactWithUs")}
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="startup__detail__page__middle_para">
          <p className="startup__detail__page__middle_para__bold__text">
            {t("Serviceintroduction")}
          </p>
          <p
            className="startup__detail__page__middle_para__bold__text"
            style={{ margin: "0" }}
          >
            {/* {startup.introduction?.catch_copy_text} */}
            {startup.introduction?.about_detail}
          </p>
          {/* <p
            className="startup__detail__page__middle_para_normal__text"
            style={{ marginTop: "3%" }}
          >
            Service introduction Text
          </p>
          <p className="startup__detail__page__middle_para_normal__text">
            {startup.introduction?.about_detail}
          </p> */}
        </div>

        {/* </div> */}
      </div>

      <div className="startup__detail__page__box__para">
        <h1 className="startup__detail__page__box__para__headline">
          {t("whywearedoingit")}
        </h1>
        <div className="startup__detail__page__box__para__textwithpic">
          <div style={{ flex: "50%" }}>
            <img
              src={startup.introduction?.why.image || DummyImage}
              alt="pic"
              className="startup__detail__page__box__para__pic"
            />
          </div>
          {/* <div style={{ flex: "50%" }}> */}
          {/* <p className="startup__detail__page__box__para__text">
              why we are doing it
            </p> */}
          <p className="startup__detail__page__box__para__text">
            {startup.introduction?.why.text}
          </p>
          {/* </div> */}
        </div>

        <h1 className="startup__detail__page__box__para__headline">
          {t("Howwedoit")}
        </h1>
        <div className="startup__detail__page__box__para__textwithpic">
          <div style={{ flex: "50%" }}>
            <img
              src={startup.introduction?.how.image || DummyImage}
              alt="pic"
              className="startup__detail__page__box__para__pic"
            />
          </div>
          {/* <div style={{ flex: "50%" }}> */}
          {/* <p className="startup__detail__page__box__para__text">
              How we do it
            </p> */}
          <p className="startup__detail__page__box__para__text">
            {startup.introduction?.how.text}
          </p>
          {/* </div> */}
        </div>
      </div>

      <div className="startup__detail__page__information__cards">
        <Basic startup={startup.info} members={startup.members} />
        {/* <Founder founders={startup.founders} />
        <Member members={startup.members} advisors={startup.advisors} /> */}
        {/* <Funding fundings={startup.funding} /> */}
      </div>
    </div>
  );
};

export default StartupDetail;
