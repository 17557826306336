import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../components/formComponent/FormInput";
import emoji from "../asset/images/emoji.png";
import eye from "../asset/images/eye.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { passwordReset } from "./api";
import Loader from "./loader/Loader";
import Modal from "./modal/Modal";
const ChangePass = () => {
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [newView, setNewView] = useState(true);
  const [confirmView, setConfirmView] = useState(true);
  const [values, setValues] = useState({
    email: user.email,
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    passMatch();
  };
  const redirectToLogin = () => {
    error ? setIsOpen(false) : window.location.replace("/login");
    setIsOpen(false);
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const success = () => {
    setError(false);
    setLoading(false);
    setIsOpen(true);
  };
  const failed = () => {
    setError(true);
    setLoading(false);
    setIsOpen(true);
  };
  const passMatch = () => {
    if (values.password === values.cpassword) {
      passwordReset(user.id, values).then((res) => {
        res.data.status_code === 200 ? success() : failed();
      });
    } else {
      setError(true);
      setLoading(false);
    }
  };
  return (
    <div className="loginBox" style={{ paddinTop: "60px" }}>
      <form onSubmit={handleSubmit}>
        <div
          className="password__reset__form"
          style={{ paddingLeft: "5px", position: "relative" }}
        >
          <p
            className="password__reset__form__title"
            style={{ marginLeft: "20px" }}
          >
            You have to change the initial password !
          </p>

          <FormInput
            label="New password"
            type={newView ? "password" : "text"}
            name="password"
            onChange={onChange}
          />
          <div className="hidePass" style={{ top: "36.5%" }}>
            <img src={eye} onClick={() => setNewView(!newView)} />
          </div>
          <FormInput
            label="Confirm your password"
            type={confirmView ? "password" : "text"}
            name="cpassword"
            onChange={onChange}
          />

          <div className="hidePass" style={{ top: "80%" }}>
            <img src={eye} onClick={() => setConfirmView(!confirmView)} />
          </div>
        </div>
        {error ? (
          <p
            className="password__reset__error"
            style={{ paddingLeft: "25px", color: "brown" }}
          >
            *** Please type same value in confirm password field
          </p>
        ) : (
          ""
        )}
        {loading && <Loader />}
        <div className="btnDiv">
          <button className="btn-login">Submit</button>
        </div>
      </form>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="modal__send__symbol">
          {error ? (
            ""
          ) : (
            <>
              <img src={emoji} />
              <p className="modal__greeting">Thank you!</p>
              <p className="modal__send__message">
                Your Password is updated !!
              </p>
              <p className="modal__send__message">
                Please Use new password to login
              </p>
            </>
          )}
        </div>
        <input
          type="button"
          onClick={() => {
            setIsOpen(false);
            redirectToLogin();
          }}
          className="modal__end__btn"
          value={error ? "Try Again" : "Login"}
        />
      </Modal>
    </div>
  );
};

export default ChangePass;
