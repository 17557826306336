import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const ID = JSON.parse(localStorage.getItem("companyId"));

const initialState = {
  companyId: ID,
};

// Get CompanyID

export const companyIdSlice = createSlice({
  name: "companyId",
  initialState,
  reducers: {
    updateCompanyId: (state, action) => {
      state.companyId = action.payload;
      localStorage.setItem("companyId", JSON.stringify(action.payload));
    },
  },
});

export const { updateCompanyId } = companyIdSlice.actions;
export default companyIdSlice.reducer;
