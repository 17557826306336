import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import hamSlice from "./features/hamToggle/hamSlice";
import notificationSlice from "./features/notification/notificationSlice";
import languageSlice from "./features/language/languageSlice";
import companyIdSlice from "./features/companyId/companyIdSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    ham: hamSlice,
    notification: notificationSlice,
    language: languageSlice,
    companyId: companyIdSlice,
  },
});
