import React, { useState } from "react";
import "./companyDetail.css";
import { useTranslation } from "react-i18next";
const Basic = ({ company }) => {
  const [showMore, setShowMore] = useState(true);
  const { t } = useTranslation();
  return (
    <div className="basic__info">
      <h1 className="basic__info__title">{t("CompanyInformations")}</h1>
      <hr />
      <div className="basic__info__informations">
        <div className="basic__info__informations__default">
          <div className="basic__info__company__informations__default__left">
            <div className="main-field">
              <p className="main-field__static__Field">{t("Company")}</p>
              <span className="main-field__dynamic_field">
                {company && company.companyName}
              </span>
            </div>

            <div className="main-field company__page__logo">
              <p
                className="main-field__static__Field"
                style={{ paddingBottom: "0" }}
              >
                {t("Logo")}
              </p>
              <img
                src={company && company.companyLogo}
                style={{ width: "150px", height: "60px" }}
              />
            </div>
          </div>
          <div className="basic__info__company__informations__default__right">
            <div className="main-field">
              <p className="main-field__static__Field">{t("Address")}</p>
              <span className="main-field__dynamic_field">
                {company && company.address}
              </span>
            </div>
            <div className="main-field">
              <p className="main-field__static__Field">{t("Web")}</p>
              <span className="main-field__dynamic_field">
                {company && company.websiteUrl}
              </span>
            </div>

            <div className="main-field">
              <p className="main-field__static__Field">{t("Capital")}</p>
              <span className="main-field__dynamic_field">
                {company && company.capital}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Basic;
