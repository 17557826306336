import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { useTranslation } from "react-i18next";
export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer__upper__section">
        <div className="footer__upper__section__about">
          <p className="footer__upper__section__about__title">
            {t("AboutCreww")}
          </p>
          <a
            className="footer__upper__section__about__info"
            href="https://en.creww.in/corp"
            style={{ color: "white" }}
          >
            {t("CompanyInfo")}
          </a>
        </div>
        <div className="footer__upper__section__contact">
          <p className="footer__upper__section__contact__title">
            {t("Contact")}
          </p>
          <p
            className="footer__upper__section__contact__info"
            style={{ width: "248px" }}
          >
            {t("IfYouHave")}
          </p>
        </div>
        <div className="footer__upper__section__list">
          <ul className="footer__upper__section__list_ul">
            <li>
              <a href="https://creww.me/ja/about/terms" target="_blank">
                {t("Termsofuse")}
              </a>
            </li>
            <li>
              <a href="https://creww.in/privacypolicy" target="_blank">
                {t("PrivacyPolicy")}
              </a>
            </li>
            <li>
              <Link to="/en/sitemap" style={{ color: "white" }}>
                {t("SiteMap")}
              </Link>
            </li>
          </ul>
        </div>
        <hr style={{ border: "1px solid #3C3C3C" }} />
      </div>
      <div className="footer__lower__section">© 2022 Creww Inc</div>
    </div>
  );
}
