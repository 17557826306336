import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const localeValue = window.location.href.split("/")[3];
// console.log(
//   window.location.href.slice(3, 3),
//   "from feature................................."
// );
const initialState = {
  english: localeValue === "jp" ? false : true,
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLan: (state) => {
      state.english = !state.english;
    },
  },
});

export const { changeLan } = languageSlice.actions;
export default languageSlice.reducer;
