import React from "react";
import ReactDOM from "react-dom";
import App from "../components/App";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "../store";
import { Provider } from "react-redux";
import i18n from "./i18n";

i18n;
document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <>
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </>,
    document.body.appendChild(document.createElement("div"))
  );
});
