import React, { useEffect } from "react";
import { Route, Link, useNavigate, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, login } from "../features/auth/authSlice";
// import "./components/loader/loader.css";
import Loader from "./loader/Loader";
export default function RedirectComponent() {
  const { user } = useSelector((state) => state.auth);
  const { companyId } = useSelector((state) => state.companyId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { english } = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(login());
    setTimeout(() => {
      console.log(user, "user Info");

      if (companyId != 0) {
        navigate(`/${english ? "en" : "jp"}/form/${companyId}`);
      }
      if (companyId === 0) {
        navigate(`/${english ? "en" : "jp"}`);
      }
      if (companyId == null) {
        navigate(`/${english ? "en" : "jp"}`);
      }
    }, 2000);
  }, []);
  return (
    <div style={{ height: "600px" }}>
      <Loader />
    </div>
  );
}
