import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import "./profilePageMessanger.css";
import Message from "../message/Message";
import { over } from "stompjs";
import SockJS from "sockjs-client";
import NoMsg from "../../asset/images/noMsg.png";
import { addAttachment } from "../api";
import wordFile from "../../asset/images/word.png";
import excelFile from "../../asset/images/excel.png";
import pdfFile from "../../asset/images/pdf.png";
import DotLoader from "../loader/DotLoader";

// const API_URL2 = "http://172.20.10.7:8080/";
// const API_URL = "https://tw-dev.creww.me/";
// const API_URL2 = "http://192.168.19.144:8080/";
const API_URL2 = "https://creww:creww@tw-dev.creww.me/";
// const API_URL = process.env.REACT_APP_API_URL;
var stompClient = null;

const ProfilePageMessanger = ({
  currentChat,
  messages,
  setMessages,
  setPageNum,
  arrivalMessage,
  setArrivalMessage,
  loadingMessage,
  receiverOrganization,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [newMessage, setNewMessage] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [socketError, setSocketError] = useState(false);
  const [loading, setLoading] = useState(false);
  const socket = useRef();
  const scrollRef = useRef(null);

  const observer = useRef();
  const lastMsgRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      console.log(entries, "entries");
      if (entries[0].isIntersecting) {
        console.log("last msg");
        setPageNum((prevPageNum) => prevPageNum + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    setNewMessage("");
  }, [currentChat?.chatId]);

  useEffect(() => {
    const newArry = [...messages];
    arrivalMessage &&
      currentChat?.chatId === arrivalMessage?.chatId &&
      setTimeout(() => {
        newArry.splice(0, 0, arrivalMessage);
        setMessages(newArry);
      }, 500);
  }, [arrivalMessage]);

  const connect = () => {
    let Sock = new SockJS(`${API_URL2}api/creww-itri-java-ws`);
    stompClient = over(Sock);
    var headers = { username: "creww", password: "creww" };
    stompClient.connect({}, onConnected, onError);
  };

  const onConnected = () => {
    stompClient.subscribe(
      "/user/" + currentChat?.chatId + "/private",
      onMessageReceived
    );
  };

  const onMessageReceived = (payload) => {
    var payloadData = JSON.parse(payload.body);
    console.log(payloadData, "payload");
    setArrivalMessage({
      senderName: payloadData.senderName,
      originalText: payloadData.originalText,
      receiverName: payloadData.receiverName,
      translatedText: payloadData.translatedText,
      senderEmail: payloadData.senderEmail,
      chatId: payloadData.chatId,
      senderLogo: payloadData.senderLogo,
      createdAt: Date.now(),
      status: payloadData.status,
      contents: payloadData.contents,
    });
  };
  const onError = (err) => {
    setSocketError(true);
    console.log(err, "Subcribtion Error");
  };
  console.log(arrivalMessage, "ArrivalMessage");
  useEffect(() => {
    connect();
  }, [currentChat]);

  const formData = new FormData();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // let files = attachment;
    attachment.forEach((tag) => formData.append("contentFiles", tag));

    if (attachment) {
      addAttachment(formData)
        .then((res) => {
          console.log(
            res.data.data.map((x) => x.contentId).toString(),
            "content"
          );
          if (stompClient) {
            var chatMessage = {
              senderEmail: user.email,
              receiverEmail: currentChat.receiverEmail,
              message: newMessage,
              status: "UNREAD",
              chatStatus: "ENABLE",
              contentIds: res.data.data.map((x) => x.contentId).toString(),
            };

            stompClient.send(
              "/app/one-to-one-message",
              { id: currentChat.chatId },
              JSON.stringify(chatMessage)
            );
          }
          setAttachment([]);
        })
        .catch((err) => console.log(err, "attachment error"));
      console.log(chatMessage, "Send Chat Message");
    }
    if (attachment.length === 0) {
      if (stompClient) {
        var chatMessage = {
          senderEmail: user.email,
          receiverEmail: currentChat.receiverEmail,
          message: newMessage,
          status: "UNREAD",
          chatStatus: "ENABLE",
          contents: null,
        };

        stompClient.send(
          "/app/one-to-one-message",
          { id: currentChat.chatId },
          JSON.stringify(chatMessage)
        );
      }
      console.log(chatMessage, "Send Chat Message");
    }

    setNewMessage("");
    document.getElementById("textID").focus();
    document.getElementById("textID").style.height = "103px";
  };

  function attachmentUpload(event) {
    setAttachment([...attachment, event.target.files[0]]);
  }
  function deletedAttachment(i) {
    const temp = [...attachment];
    temp.splice(i, 1);
    setAttachment(temp);
  }

  useEffect(() => {
    setAttachment([]);
  }, [currentChat]);

  useEffect(() => {
    setTimeout(() => {
      setSocketError(false);
    }, 3000);
  }, [socketError]);

  function handleKeyDown(e) {
    e.target.style.height = "inherit";
    e.target.style.height = "103px";
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  // console.log(currentChat, "currentChat");
  return (
    <div className="profile__page__messanger__wrapper">
      <div className="profile__page__messanger__chat__display">
        {socketError ? (
          <div className="socket_error_tool_tip">
            Server not connected. Please wait ...{" "}
          </div>
        ) : (
          ""
        )}

        <div
          style={{ position: "relative" }}
          className="profile__page__messanger__chat__area__attachment__show"
        >
          {attachment.length > 0 &&
            attachment
              .filter((e) => e)
              .map((x, i) => (
                <div
                  className="profile__page__messanger__chat__area__attachment__show__one"
                  style={{
                    padding:
                      x.name.split(".")[1] == "png" ||
                      x.name.split(".")[1] == "jpeg" ||
                      x.name.split(".")[1] == "jpg"
                        ? "0"
                        : "10px",
                    border:
                      x.name.split(".")[1] == "png" ||
                      x.name.split(".")[1] == "jpeg" ||
                      x.name.split(".")[1] == "jpg"
                        ? "none"
                        : "1px solid #e6e6e6",
                  }}
                >
                  {x.name.split(".")[1] == "png" ||
                  x.name.split(".")[1] == "jpeg" ||
                  x.name.split(".")[1] == "jpg" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "240px",
                        maxHeight: "160px",
                      }}
                    >
                      <img
                        src={URL.createObjectURL(x)}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "6px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="profile__page__messanger__chat__area__attachment__image__name">
                          {x.name}
                        </p>
                        {/* <p
                          className="profile__page__messanger__chat__area__attachment__show__one__download"
                          style={{ margin: "0" }}
                        >
                          download
                        </p> */}
                      </div>
                    </div>
                  ) : (
                    <>
                      <img
                        src={
                          x.name.split(".")[1] === "docx"
                            ? wordFile
                            : x.name.split(".")[1] === "pdf" ||
                              x.name.split(".")[1] == "pptx"
                            ? pdfFile
                            : excelFile
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p className="profile__page__messanger__chat__area__attachment__show__one__name">
                          {x.name}
                        </p>
                        {/* <p className="profile__page__messanger__chat__area__attachment__show__one__download">
                          download
                        </p> */}
                      </div>
                    </>
                  )}

                  <span
                    className="profile__page__messanger__chat__area__attachment__show__one__close__btn"
                    onClick={() => deletedAttachment(i)}
                    style={{
                      position: "absolute",
                      top: "-3%",
                      right: "-3%",
                      fontSize: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#B4B4B4" />
                      <path
                        d="M11.8225 4.18354C11.5859 3.94691 11.2036 3.94691 10.967 4.18354L8 7.14448L5.03299 4.17747C4.79636 3.94084 4.41411 3.94084 4.17747 4.17747C3.94084 4.41411 3.94084 4.79636 4.17747 5.03299L7.14448 8L4.17747 10.967C3.94084 11.2036 3.94084 11.5859 4.17747 11.8225C4.41411 12.0592 4.79636 12.0592 5.03299 11.8225L8 8.85552L10.967 11.8225C11.2036 12.0592 11.5859 12.0592 11.8225 11.8225C12.0592 11.5859 12.0592 11.2036 11.8225 10.967L8.85552 8L11.8225 5.03299C12.0531 4.80243 12.0531 4.41411 11.8225 4.18354Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              ))}
        </div>

        {currentChat.chatId ? (
          <>
            {loadingMessage && <DotLoader />}
            {messages &&
              messages.map((m, i, arr) => {
                const prev = arr[i + 1];

                if (i === messages.length - 1) {
                  return (
                    <div key={i} ref={lastMsgRef} style={{ width: "100%" }}>
                      {m.senderEmail && m.senderEmail === prev?.senderEmail ? (
                        <Message
                          messages={m}
                          sameSender={true}
                          prev={prev}
                          receiverOrganization={receiverOrganization}
                        />
                      ) : (
                        <Message
                          messages={m}
                          prev={prev}
                          receiverOrganization={receiverOrganization}
                        />
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div key={i} style={{ width: "100%" }}>
                      {i >= 0 &&
                      m.senderEmail &&
                      m.senderEmail === prev?.senderEmail ? (
                        <Message
                          messages={m}
                          sameSender={true}
                          prev={prev}
                          receiverOrganization={receiverOrganization}
                        />
                      ) : (
                        <Message
                          messages={m}
                          prev={prev}
                          receiverOrganization={receiverOrganization}
                        />
                      )}
                    </div>
                  );
                }
              })}

            <div ref={scrollRef} />
          </>
        ) : (
          <div className="profile__page__messanger__chat__display__no_message">
            <span style={{ marginLeft: "30px" }}>You have no message yet</span>
            <img src={NoMsg} />
          </div>
        )}
      </div>
      <div className="profile__page__messanger__chat__area">
        <div className="profile__page__messanger__chat__area__attachment">
          <div className="profile__page__messanger__chat__area__attachment__container">
            <input
              id="input-file"
              type="file"
              name="myfile"
              onChange={attachmentUpload}
              className="profile__page__messanger__chat__area__attachment__input"
              style={{ margin: "0" }}
            />
            <svg
              for="input-file"
              width="8"
              height="15"
              viewBox="0 0 8 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="profile__page__messanger__chat__area__attachment__logo"
            >
              <path
                d="M6.39091 3.36364V11.1C6.39091 12.5867 5.18673 13.7909 3.7 13.7909C2.21327 13.7909 1.00909 12.5867 1.00909 11.1V2.69091C1.00909 1.76255 1.76255 1.00909 2.69091 1.00909C3.61927 1.00909 4.37273 1.76255 4.37273 2.69091V9.75455C4.37273 10.1245 4.07 10.4273 3.7 10.4273C3.33 10.4273 3.02727 10.1245 3.02727 9.75455V3.36364H2.01818V9.75455C2.01818 10.6829 2.77164 11.4364 3.7 11.4364C4.62836 11.4364 5.38182 10.6829 5.38182 9.75455V2.69091C5.38182 1.20418 4.17764 0 2.69091 0C1.20418 0 0 1.20418 0 2.69091V11.1C0 13.1451 1.65491 14.8 3.7 14.8C5.74509 14.8 7.4 13.1451 7.4 11.1V3.36364H6.39091Z"
                fill="#838383"
              />
            </svg>
          </div>
        </div>
        <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
          <textarea
            id="textID"
            className="profile__page__messanger__chat__area__textarea"
            type="text-area"
            placeholder="Enter Message"
            onChange={(e) => setNewMessage(e.target.value)}
            value={newMessage}
            onKeyDown={handleKeyDown}
            maxLength={500}
          />
        </div>
        {Object.keys(currentChat).length === 0 ||
        (user.belongs_to === "STARTUP" &&
          currentChat.chatStatus === "BLOCK") ? (
          ""
        ) : (
          // <div className="profile__page__messanger__chat__area__button__deactive">
          //   <svg
          //     width="32"
          //     height="32"
          //     viewBox="0 0 32 32"
          //     fill={newMessage ? "#E6E6E6" : "none"}
          //     xmlns="http://www.w3.org/2000/svg"
          //   >
          //     <path
          //       d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
          //       fill="#E6E6E6"
          //     />
          //     <path
          //       d="M10.1726 21.25L22.4167 16L10.1726 10.75L10.1667 14.8333L18.9167 16L10.1667 17.1667L10.1726 21.25Z"
          //       fill="white"
          //     />
          //   </svg>
          // </div>
          <div
            className="profile__page__messanger__chat__area__button"
            onClick={newMessage && handleSubmit}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill={newMessage ? "#E6E6E6" : "none"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                fill={newMessage ? "#0094BB" : "#B4B4B4"}
              />
              <path
                d="M10.1726 21.25L22.4167 16L10.1726 10.75L10.1667 14.8333L18.9167 16L10.1667 17.1667L10.1726 21.25Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePageMessanger;
