import React, { useState, useEffect } from "react";
import "./applicationMessage.css";
import yahoo from "../../asset/images/yahoo.png";
import { useSelector } from "react-redux";
import moment from "moment";
import wordFile from "../../asset/images/word.png";
import excelFile from "../../asset/images/excel.png";
import pdfFile from "../../asset/images/pdf.png";
import { singleTranslatedText } from "../api/index";
import { Link } from "react-router-dom";
export default function ApplicationMessage({ messages, sameSender }) {
  const { english } = useSelector((state) => state.language);
  const { user } = useSelector((state) => state.auth);
  const [translateAchieve, setTranslateAchieve] = useState("");
  const [translate, setTranslate] = useState(false);

  const applicationText = JSON.parse(messages.originalText);
  const applicationTextTranslate = JSON.parse(messages.translatedText);

  useEffect(() => {
    applicationText &&
      singleTranslatedText(applicationText.achieve).then((res) => {
        setTranslateAchieve(res.data.data.translatedText);
      });
  }, []);
  console.log(applicationText, "Original");
  // console.log(translateAchieve, "translated achieve");
  // console.log(applicationTextTranslate, "Application");
  return (
    <div className="application__form">
      <div className="message__bottom">
        {moment(messages.createdAt).format("L")}
      </div>
      {user.belongs_to === "COMPANY" && (
        <div
          className="application__form__notification"
          style={{ margin: "21px 0 0 0" }}
        >
          <img
            src={yahoo}
            style={{ width: "14px", height: "14px", marginTop: "2px" }}
          />{" "}
          <p style={{ margin: "0" }}>
            スタートアップからのエントリーです！内容を確認して返信してください。{" "}
          </p>
        </div>
      )}
      <div className="message__top">
        <img className="message__top__image" src={messages.senderLogo} />
        <div className="message__top__right">
          <p className="message__top__right__name">{messages.senderName}</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="message__top__right__company">
              {user.belongs_to === "STARTUP"
                ? applicationText.companyName
                : applicationTextTranslate.startupName}
            </p>
            <p className="message__top__right_time">
              {moment.utc(messages.createdAt).local().format("h:mm a")}
            </p>
          </div>
        </div>
      </div>
      {user.belongs_to === "STARTUP" && (
        <div
          className="application__form__notification"
          style={{ marginBottom: "10px" }}
        >
          <img
            src={yahoo}
            style={{
              width: "14px",
              height: "14px",
              marginTop: "2px",
            }}
          />
          <p style={{ margin: "0" }}>
            Your proposals has been submitted! Please wait their replay
          </p>
        </div>
      )}
      <div className="application__form__main">
        <div className="application__form__startup__info">
          <div className="application__form__startup__info__logo">
            <img
              src={
                user.belongs_to === "STARTUP"
                  ? applicationText.companyLogo
                  : applicationTextTranslate.startupLogo
              }
              style={{ width: "45px", height: "45px", borderRadius: "50%" }}
            />
          </div>
          <div className="application__form__startup__info__name__url">
            <div className="application__form__startup__info__name">
              {user.belongs_to === "STARTUP"
                ? applicationText.companyName
                : applicationTextTranslate.startupName}
            </div>
            {user.belongs_to === "COMPANY" && (
              <div className="application__form__startup__info__startup__name">
                {applicationTextTranslate.startupUser}
              </div>
            )}
            <div className="application__form__startup__info__url">
              <Link
                to={
                  user.belongs_to === "STARTUP"
                    ? `/${english ? "en" : "jp"}/company-detail/${
                        applicationText.companyId
                      }`
                    : `/${english ? "en" : "jp"}/startup-detail/${
                        applicationTextTranslate.startupId
                      }`
                }
                style={{ color: "#1D1C1D", fontSize: "14px" }}
              >
                {user.belongs_to === "COMPANY"
                  ? "Startup Page URL"
                  : "Company Page URL"}
              </Link>
              {/* {user.belongs_to === "STARTUP"
                ? applicationText.companyUrl
                : applicationTextTranslate.startupUrl} */}
            </div>
          </div>
        </div>

        {!translate
          ? user.belongs_to === "STARTUP"
            ? applicationText.data &&
              applicationText.data
                .filter((x) => x.answer != "")
                .map((qa, i) => (
                  <>
                    <div className="application__form__question__answer">
                      <hr />
                      <div className="application__form__question">
                        <p className="application__form__question__text">
                          {"Q" + (i + 1) + " " + qa.questionText}
                        </p>
                      </div>

                      <div className="application__form__answer">
                        <p className="application__form__answer__text">
                          {qa.answer}
                        </p>
                      </div>
                    </div>
                  </>
                ))
            : applicationTextTranslate.data
                .filter((x) => x.answer != "")
                .map((qa, i) => (
                  <>
                    <div className="application__form__question__answer">
                      <hr />
                      <div className="application__form__question">
                        <p className="application__form__question__text">
                          {"Q" + (i + 1) + " " + qa.questionText}
                        </p>
                      </div>

                      <div className="application__form__answer">
                        <p className="application__form__answer__text">
                          {qa.answer}
                        </p>
                      </div>
                    </div>
                  </>
                ))
          : user.belongs_to === "COMPANY"
          ? applicationText.data &&
            applicationText.data
              .filter((x) => x.answer != "")
              .map((qa, i) => (
                <>
                  <div className="application__form__question__answer">
                    <hr />
                    <div className="application__form__question">
                      <p className="application__form__question__text">
                        {"Q" + (i + 1) + " " + qa.questionText}
                      </p>
                    </div>

                    <div className="application__form__answer">
                      <p className="application__form__answer__text">
                        {qa.answer}
                      </p>
                    </div>
                  </div>
                </>
              ))
          : applicationTextTranslate.data
              .filter((x) => x.answer != "")
              .map((qa, i) => (
                <>
                  <div className="application__form__question__answer">
                    <hr />
                    <div className="application__form__question">
                      <p className="application__form__question__text">
                        {"Q" + (i + 1) + " " + qa.questionText}
                      </p>
                    </div>

                    <div className="application__form__answer">
                      <p className="application__form__answer__text">
                        {qa.answer}
                      </p>
                    </div>
                  </div>
                </>
              ))}
        <div className="application__form__question__answer">
          <hr />
          <div className="application__form__question">
            <p className="application__form__question__text">
              {!translate
                ? user.belongs_to === "COMPANY"
                  ? "あなたのビジネス/サービス/会社の強み"
                  : "Strengths of your business/ service/ company"
                : user.belongs_to === "STARTUP"
                ? "あなたのビジネス/サービス/会社の強み"
                : "Strengths of your business/ service/ company"}
            </p>
          </div>

          <div className="application__form__answer">
            <p className="application__form__answer__text">
              {!translate
                ? user.belongs_to === "COMPANY"
                  ? translateAchieve
                  : applicationText.achieve
                : user.belongs_to === "STARTUP"
                ? translateAchieve
                : applicationText.achieve}
            </p>
          </div>
        </div>

        <div
          className="message__top__right__message__box__message__translate"
          onClick={() => setTranslate(!translate)}
        >
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "2px" }}
          >
            <path
              d="M7.01422 8.04518C7.09149 7.84648 7.04182 7.62018 6.88727 7.46563L5.73369 6.32861L5.75025 6.31205C6.71065 5.24126 7.39507 4.01041 7.79799 2.70781H8.86878C9.16683 2.70781 9.41521 2.45943 9.41521 2.16137V2.15034C9.41521 1.85228 9.16683 1.6039 8.86878 1.6039H5.55155V1.05195C5.55155 0.748378 5.30317 0.5 4.9996 0.5C4.69602 0.5 4.44765 0.748378 4.44765 1.05195V1.6039H1.13042C0.832363 1.6039 0.583984 1.85228 0.583984 2.15034C0.583984 2.45391 0.832363 2.69677 1.13042 2.69677H6.74928C6.37948 3.76755 5.79441 4.77763 4.9996 5.66075C4.55252 5.16951 4.17719 4.63412 3.86258 4.07113C3.77426 3.91106 3.6142 3.81171 3.43205 3.81171C3.05121 3.81171 2.80835 4.22567 2.99601 4.55685C3.34374 5.18055 3.76875 5.77666 4.2655 6.32861L1.85347 8.70752C1.63269 8.92278 1.63269 9.27603 1.85347 9.49129C2.06873 9.70656 2.41646 9.70656 2.63724 9.49129L4.9996 7.12342L6.11454 8.23836C6.39604 8.51986 6.87623 8.41499 7.01422 8.04518ZM9.69119 4.91561C9.36002 4.91561 9.06196 5.11984 8.94605 5.43445L6.92039 10.8436C6.78792 11.1803 7.04182 11.539 7.40059 11.539C7.61585 11.539 7.80903 11.4066 7.8863 11.2023L8.37754 9.88318H10.9993L11.4961 11.2023C11.5733 11.401 11.7665 11.539 11.9818 11.539C12.3406 11.539 12.5945 11.1803 12.4675 10.8436L10.4418 5.43445C10.3204 5.11984 10.0224 4.91561 9.69119 4.91561ZM8.79702 8.77928L9.69119 6.38932L10.5853 8.77928H8.79702Z"
              fill="#0094BB"
            />
          </svg>
          <p className="message__top__right__message__box__message__translate__text">
            {user.email === messages.senderEmail
              ? !translate
                ? "View Translated Text"
                : "View Original Text"
              : user.email != messages.senderEmail
              ? !translate
                ? "View Original Text"
                : "View Translated Text"
              : "none"}
          </p>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          margin: applicationText.contents
            ? "8px 60px 0px 60px"
            : "0 !important",
          display: applicationText.contents ? "" : "none",
        }}
        className="message__attachment__show"
      >
        {applicationText.contents &&
          applicationText.contents.map((x, i) => (
            <div
              className="message__attachment__show__one"
              style={{
                padding:
                  x.contentName.split(".")[1] == "png" ||
                  x.contentName.split(".")[1] == "jpeg" ||
                  x.contentName.split(".")[1] == "jpg"
                    ? "0"
                    : "10px",
                border:
                  x.contentName.split(".")[1] == "png" ||
                  x.contentName.split(".")[1] == "jpeg" ||
                  x.contentName.split(".")[1] == "jpg"
                    ? "none"
                    : "1px solid #e6e6e6",
                margin: "20px 0",
              }}
            >
              {x.contentName.split(".")[1] == "png" ||
              x.contentName.split(".")[1] == "jpeg" ||
              x.contentName.split(".")[1] == "jpg" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "240px",
                    maxHeight: "160px",
                  }}
                >
                  <img
                    src={x.contentUrl}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 0 0 0",
                    }}
                  >
                    <p className="message__attachment__image__name">
                      {x.contentName}
                    </p>
                    <a
                      className="profile__page__messanger__chat__area__attachment__show__one__download"
                      style={{ margin: "0" }}
                      href={x.contentUrl}
                    >
                      download
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  <img
                    src={
                      x.contentName.split(".")[1] === "docx" ||
                      x.contentName.split(".")[1] == "doc"
                        ? wordFile
                        : x.contentName.split(".")[1] === "pdf" ||
                          x.contentName.split(".")[1] == "pptx"
                        ? pdfFile
                        : excelFile
                    }
                    style={{ width: "40px", height: "40px" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p className="profile__page__messanger__chat__area__attachment__show__one__name">
                      {x.contentName}
                    </p>
                    <a
                      className="profile__page__messanger__chat__area__attachment__show__one__download"
                      style={{ margin: "0" }}
                      href={x.contentUrl}
                    >
                      download
                    </a>
                  </div>
                </>
              )}

              <span
                className="profile__page__messanger__chat__area__attachment__show__one__close__btn"
                style={{
                  position: "absolute",
                  top: "-8%",
                  right: "-6%",
                  fontSize: "10px",
                  cursor: "pointer",
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#B4B4B4" />
                  <path
                    d="M11.8225 4.18354C11.5859 3.94691 11.2036 3.94691 10.967 4.18354L8 7.14448L5.03299 4.17747C4.79636 3.94084 4.41411 3.94084 4.17747 4.17747C3.94084 4.41411 3.94084 4.79636 4.17747 5.03299L7.14448 8L4.17747 10.967C3.94084 11.2036 3.94084 11.5859 4.17747 11.8225C4.41411 12.0592 4.79636 12.0592 5.03299 11.8225L8 8.85552L10.967 11.8225C11.2036 12.0592 11.5859 12.0592 11.8225 11.8225C12.0592 11.5859 12.0592 11.2036 11.8225 10.967L8.85552 8L11.8225 5.03299C12.0531 4.80243 12.0531 4.41411 11.8225 4.18354Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          ))}
      </div>
    </div>
  );
}
