import React from "react";
import "./modal.css";

const Modal = ({ children, open, onClose }) => {
  if (!open) return null;
  return (
    <>
      <div className="overlay"></div>
      <div className="modal__body">
        <button className="modal__close_btn" onClick={onClose}>
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.8215 0.837541C21.1715 0.187541 20.1215 0.187541 19.4715 0.837541L11.3215 8.97087L3.17148 0.820874C2.52148 0.170874 1.47148 0.170874 0.821484 0.820874C0.171484 1.47087 0.171484 2.52087 0.821484 3.17087L8.97149 11.3209L0.821484 19.4709C0.171484 20.1209 0.171484 21.1709 0.821484 21.8209C1.47148 22.4709 2.52148 22.4709 3.17148 21.8209L11.3215 13.6709L19.4715 21.8209C20.1215 22.4709 21.1715 22.4709 21.8215 21.8209C22.4715 21.1709 22.4715 20.1209 21.8215 19.4709L13.6715 11.3209L21.8215 3.17087C22.4548 2.53754 22.4548 1.47087 21.8215 0.837541Z"
              fill="#C8C8C8"
            />
          </svg>
        </button>

        {children}
      </div>
    </>
  );
};

export default Modal;
