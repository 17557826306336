import React, { useState, useEffect } from "react";
import "./conversation.css";
import { useSelector } from "react-redux";
import moment from "moment";

const Conversation = ({
  conversation,
  currentUser,
  selected,
  messages,
  lastMessage,
  setLastMessage,
  otherUser,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [unread, setUnread] = useState(false);

  // const callAPIs = () => {
  //   acknowledgeAPI(conversation.lastMessage.senderEmail, currentUser.chatId);
  //   setLastMessage({ ...lastMessage, status: "READ" });
  // };

  useEffect(() => {
    lastMessage?.senderEmail != otherUser.email &&
    !selected &&
    lastMessage?.status === "UNREAD"
      ? setUnread(true)
      : setUnread(false);
  }, [lastMessage]);
  // useEffect(() => {
  //   conversation.lastMessage.senderEmail != otherUser.email &&
  //   !selected &&
  //   conversation.lastMessage.status === "UNREAD"
  //     ? setUnread(true)
  //     : setUnread(false);
  // }, [conversation]);

  // useEffect(() => {
  //   selected ? callAPIs() : "";
  // }, [selected]);
  // console.log(unread, "Unread");
  // console.log(otherUser, "Other user from conversation");
  return (
    <div
      className={
        selected
          ? "conversation-active"
          : unread
          ? "conversation-unread"
          : "conversation"
      }
    >
      {lastMessage?.senderEmail != otherUser.email &&
        !selected &&
        lastMessage?.status === "UNREAD" && (
          <span className="conversation__unread"></span>
        )}
      {conversation.lastMessage.senderEmail != otherUser.email &&
      !selected &&
      conversation.lastMessage.status === "UNREAD" ? (
        <span className="conversation__unread"></span>
      ) : (
        ""
      )}
      <img src={conversation.receiverLogo} className="conversation__img" />
      <div className="conversation__text__body">
        <div className="conversation__text__body__first__line">
          <p className="conversation__text__body__first__line__name">
            {conversation.receiverName}
          </p>
          <p className="conversation__text__body__first__line__dateTime">
            {moment(conversation.lastMessage.createdAt).format("L")}{" "}
            <span> </span>
            {moment
              .utc(conversation.lastMessage.createdAt)
              .local()
              .format("LT")}
          </p>
        </div>

        <p className="conversation__text__body__company__name">
          {conversation.receiverOrganization || "COMPANY NAME"}
        </p>
        <p className="conversation__text__body__msg">
          {conversation.lastMessage.status != "PARSABLE"
            ? (lastMessage && lastMessage.senderEmail === otherUser.email
                ? lastMessage?.originalText
                : lastMessage?.translatedText) ||
              (otherUser.email === conversation.lastMessage.senderEmail
                ? conversation.lastMessage.originalText
                : conversation.lastMessage.translatedText)
            : otherUser.belongs_to === "COMPANY"
            ? "受け取った申請書"
            : "Application Form received !"}
        </p>
      </div>
    </div>
  );
};

export default Conversation;
