import React from "react";
import "./Forminput.css";

const FormInput = (props) => {
  const { label, onChange, id, ...inputProps } = props;
  return (
    <div className="formInput">
      {label ? <label>{label}</label> : null}
      <input {...inputProps} onChange={onChange} required />
    </div>
  );
};

export default FormInput;
