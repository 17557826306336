import React, { useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
export default function SiteMap() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sitemap">
      <h1 className="sitemap__title">Sitemap</h1>
      <div className="sitemap__list">
        <ul className="sitemap__ul">
          <li className="sitemap__li">
            <Link to="/" className="sitemap__name">
              Home
            </Link>
          </li>
          <li className="sitemap__li">
            <Link
              className="sitemap__name"
              to="/en/chat"
              style={{ paddingBottom: "10px", display: "block" }}
            >
              Mypage
            </Link>
            <ul style={{ listStyle: "none", paddingLeft: "10px" }}>
              <li className="sitemap__name">Account Setting</li>
              <li className="sitemap__name">Startup page</li>
            </ul>
          </li>
          <li className="sitemap__li">
            <a className="sitemap__name">Startups</a>
          </li>
          <li className="sitemap__li">
            <a className="sitemap__name">Companies</a>
          </li>
          <li className="sitemap__li">
            <a className="sitemap__name">Contact</a>
          </li>
          <li className="sitemap__li">
            <a className="sitemap__name">About us</a>
          </li>
          <li className="sitemap__li">
            <a className="sitemap__name">Privacy Policy</a>
          </li>
          <li className="sitemap__li">
            <a className="sitemap__name">Terms of use</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
