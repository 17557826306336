import React, { useState, useEffect, useRef } from "react";
import "./profile.css";
import { useSelector, useDispatch } from "react-redux";
import Conversation from "../conversations2/Conversation";
import ProfilePageInfo from "./ProfilePageInfo";
import ProfilePageMessanger from "./ProfilePageMessanger";
import NoChat from "../../asset/images/noChat.png";
import { getNotificationCount } from "../../features/notification/notificationSlice";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getAllConversations,
  getAllMessages,
  acknowledgeAPI,
  getNotification,
  otherUserInfo,
} from "../api";
import { toggle } from "../../features/hamToggle/hamSlice";
import DotLoader from "../loader/DotLoader";
const Profile = () => {
  const { otherEmail } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [otherUser, setOtherUser] = useState();
  if (!user) {
    return window.location.replace("/login");
  }
  if (user && user.password_changed === false) {
    navigate(`/${english ? "en" : "jp"}/changePass`);
  }
  const { english } = useSelector((state) => state.language);
  const headers = { accountLanguage: english ? "EN" : "JA" };
  const { count } = useSelector((state) => state.notification);
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState({});
  const [messages, setMessages] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [listViewOn, setListViewOn] = useState(true);
  const [messageMobileView, setMessageMobileView] = useState(true);
  const { isEnable } = useSelector((state) => state.ham);
  // const [msgAck, setMsgAck] = useState(false);
  const observer = useRef();
  const dispatch = useDispatch();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const otherUserInfoCall = () => {
    otherUserInfo(encodeURIComponent(otherEmail)).then((res) =>
      setOtherUser(res.data.data)
    );
  };
  useEffect(() => {
    otherUserInfoCall();
  }, []);
  useEffect(() => {
    setPageNum(0);
    setMessages([]);
  }, [currentChat.chatId]);

  const getConversations = async () => {
    try {
      const res = await getAllConversations(otherEmail, headers);
      res.data.status_code === 200 && setConversations(res.data.data);
    } catch (error) {
      console.log(error, "Conversations API Error");
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      getConversations();
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    getConversations();
    // ackMsg();
    // getNotification(user.email).then(() => {
    //   dispatch(getNotificationCount(user.email));
    // });
  }, [currentChat, arrivalMessage]);

  useEffect(() => {
    const getMessages = async () => {
      setLoadingMessage(true);
      try {
        const res = await getAllMessages(currentChat.chatId, 0, headers);
        setLoadingMessage(false);
        setMessages([...res.data.data.contents]);
      } catch (error) {
        setLoadingMessage(false);
        console.log(error);
      }
    };
    getMessages();
  }, [currentChat?.chatId]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await getAllMessages(currentChat.chatId, pageNum, headers);
        setMessages([...messages, ...res.data?.data.contents]);
      } catch (error) {
        console.log(error);
      }
    };
    getMessages();
  }, [pageNum]);

  // const ackMsg = async () => {
  //   await acknowledgeAPI(
  //     currentChat?.lastMessage?.senderEmail,
  //     currentChat?.chatId
  //   )
  //     .then(function (response) {
  //       setMsgAck(!ackMsg);
  //     })
  //     .catch(function (error) {
  //       console.log(error, "error from ack");
  //     });
  // };
  const handleConversation = async (c) => {
    setCurrentChat(currentChat != c && c);
    setMessageMobileView(!messageMobileView);
    // await ackMsg().then(() => {
    //   dispatch(getNotificationCount(user.email));
    // });
    await getConversations().then(() => {
      setArrivalMessage(null);
    });
  };

  return (
    <div
      className="profile__page"
      style={{
        gridTemplateColumns: listViewOn ? "1.5fr 5fr 1.7fr" : "1.3fr 5fr",
      }}
    >
      {!otherUser ? (
        <DotLoader />
      ) : (
        <>
          <div
            className="profile__page__info"
            style={{ visibility: isEnable ? "visible" : "" }}
          >
            <ProfilePageInfo otherUser={otherUser} />
            <div
              className="overlay__mypage"
              onClick={() => dispatch(toggle())}
            ></div>
          </div>
          <div
            className="profile__page_messanger"
            style={{
              visibility: !messageMobileView ? "visible" : "",
              height: "calc(100vh - 66px)",
            }}
          >
            <div
              className="profile__page_messanger__top__bar"
              style={{ display: "flex" }}
            >
              {currentChat?.chatId ? (
                <>
                  <ul
                    style={{
                      listStyle: "none",
                      padding: "10px",
                      display: "flex",
                    }}
                    className="profile__page_messanger__top__bar__ul"
                  >
                    <li
                      className="profile__page_messanger__mobile__back__arrow"
                      onClick={() => {
                        setCurrentChat({});
                        setMessageMobileView(!messageMobileView);
                      }}
                      style={{
                        visibility: !messageMobileView
                          ? "visible !important"
                          : "",
                      }}
                    >
                      <svg
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.72611 9.90285C6.08133 9.54763 6.08133 8.97381 5.72611 8.6186L2.20125 5.08463L5.73521 1.55066C6.09043 1.19545 6.09043 0.621632 5.73521 0.266414C5.38 -0.0888046 4.80618 -0.0888046 4.45096 0.266414L0.27032 4.44706C-0.0848983 4.80228 -0.0848983 5.37609 0.27032 5.73131L4.45096 9.91195C4.79707 10.2581 5.37089 10.2581 5.72611 9.90285Z"
                          fill="#838383"
                        />
                      </svg>
                    </li>
                    <li>
                      <img
                        src={user.logo}
                        style={{
                          width: "16px",
                          height: "16px",
                          borderRadius: "8px",
                        }}
                      />
                    </li>
                    <li style={{ left: "-2px" }}>
                      <img
                        src={currentChat?.receiverLogo}
                        style={{
                          width: "16px",
                          height: "16px",
                          borderRadius: "8px",
                        }}
                      />
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                      <p>{otherUser.first_name + " " + otherUser.last_name}</p>
                    </li>{" "}
                    <li>
                      <p>
                        {(currentChat &&
                          "," + " " + currentChat?.receiverName) ||
                          "No Name"}
                      </p>
                    </li>
                  </ul>
                </>
              ) : (
                ""
              )}
              {listViewOn ? (
                <div
                  className="profile__page_messanger__toggle__hide"
                  style={{ opacity: "0" }}
                >
                  <ul className="profile__page_messanger__toggle__hide__list">
                    <li>
                      <svg
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.72611 9.90285C6.08133 9.54763 6.08133 8.97381 5.72611 8.6186L2.20125 5.08463L5.73521 1.55066C6.09043 1.19545 6.09043 0.621632 5.73521 0.266414C5.38 -0.0888046 4.80618 -0.0888046 4.45096 0.266414L0.27032 4.44706C-0.0848983 4.80228 -0.0848983 5.37609 0.27032 5.73131L4.45096 9.91195C4.79707 10.2581 5.37089 10.2581 5.72611 9.90285Z"
                          fill="#838383"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.72611 9.90285C6.08133 9.54763 6.08133 8.97381 5.72611 8.6186L2.20125 5.08463L5.73521 1.55066C6.09043 1.19545 6.09043 0.621632 5.73521 0.266414C5.38 -0.0888046 4.80618 -0.0888046 4.45096 0.266414L0.27032 4.44706C-0.0848983 4.80228 -0.0848983 5.37609 0.27032 5.73131L4.45096 9.91195C4.79707 10.2581 5.37089 10.2581 5.72611 9.90285Z"
                          fill="#838383"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="profile__page_messanger__toggle__hide">
                  <ul
                    className="profile__page_messanger__toggle__hide__list"
                    onClick={() => setListViewOn(true)}
                  >
                    <li>
                      <svg
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.72611 9.90285C6.08133 9.54763 6.08133 8.97381 5.72611 8.6186L2.20125 5.08463L5.73521 1.55066C6.09043 1.19545 6.09043 0.621632 5.73521 0.266414C5.38 -0.0888046 4.80618 -0.0888046 4.45096 0.266414L0.27032 4.44706C-0.0848983 4.80228 -0.0848983 5.37609 0.27032 5.73131L4.45096 9.91195C4.79707 10.2581 5.37089 10.2581 5.72611 9.90285Z"
                          fill="#838383"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.72611 9.90285C6.08133 9.54763 6.08133 8.97381 5.72611 8.6186L2.20125 5.08463L5.73521 1.55066C6.09043 1.19545 6.09043 0.621632 5.73521 0.266414C5.38 -0.0888046 4.80618 -0.0888046 4.45096 0.266414L0.27032 4.44706C-0.0848983 4.80228 -0.0848983 5.37609 0.27032 5.73131L4.45096 9.91195C4.79707 10.2581 5.37089 10.2581 5.72611 9.90285Z"
                          fill="#838383"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <ProfilePageMessanger
              currentChat={currentChat && currentChat}
              messages={messages}
              setMessages={setMessages}
              pageNum={pageNum}
              setPageNum={setPageNum}
              arrivalMessage={arrivalMessage}
              setArrivalMessage={setArrivalMessage}
              loadingMessage={loadingMessage}
              receiverOrganization={currentChat.receiverOrganization}
              otherUser={otherUser}
            />
          </div>
          {listViewOn ? (
            <div
              className="profile__page__message__history"
              style={{ visibility: !messageMobileView ? "" : "visible" }}
            >
              <div className="profile__page__message__history__toggle_hide">
                <ul
                  className="profile__page__message__history__toggle__hide__ul"
                  onClick={() => setListViewOn(false)}
                >
                  <li style={{ paddingLeft: "6px", paddingTop: "4px" }}>
                    <svg
                      width="6"
                      height="11"
                      viewBox="0 0 6 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.268367 0.267563C-0.0868514 0.622782 -0.0868514 1.1966 0.268367 1.55181L3.80233 5.08578L0.268367 8.61975C-0.0868514 8.97496 -0.0868514 9.54878 0.268367 9.904C0.623585 10.2592 1.1974 10.2592 1.55262 9.904L5.73326 5.72335C6.08848 5.36813 6.08848 4.79432 5.73326 4.4391L1.55262 0.258455C1.20651 -0.087655 0.623585 -0.0876548 0.268367 0.267563Z"
                        fill="#838383"
                      />
                    </svg>
                  </li>
                  <li style={{ paddingTop: "4px" }}>
                    <svg
                      width="6"
                      height="11"
                      viewBox="0 0 6 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.268367 0.267563C-0.0868514 0.622782 -0.0868514 1.1966 0.268367 1.55181L3.80233 5.08578L0.268367 8.61975C-0.0868514 8.97496 -0.0868514 9.54878 0.268367 9.904C0.623585 10.2592 1.1974 10.2592 1.55262 9.904L5.73326 5.72335C6.08848 5.36813 6.08848 4.79432 5.73326 4.4391L1.55262 0.258455C1.20651 -0.087655 0.623585 -0.0876548 0.268367 0.267563Z"
                        fill="#838383"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
              {conversations && conversations.length == 0 && (
                <div className="profile__page__message__history__no__chat">
                  {" "}
                  <p>"Your Message box is empty"</p>
                  <img src={NoChat} />
                </div>
              )}
              <div style={{ overflow: "scroll", height: "90%" }}>
                {conversations?.map((c) => (
                  <div
                    onClick={() => handleConversation(c)}
                    className="conversation__container"
                  >
                    {currentChat?.chatId === c.chatId ? (
                      <Conversation
                        conversation={c}
                        currentUser={user}
                        selected={true}
                        messages={messages}
                        lastMessage={
                          arrivalMessage &&
                          arrivalMessage.chatId === c.chatId &&
                          arrivalMessage
                        }
                        setLastMessage={setArrivalMessage}
                        otherUser={otherUser}
                      />
                    ) : (
                      <Conversation
                        conversation={c}
                        currentUser={user}
                        selected={false}
                        messages={messages}
                        lastMessage={
                          arrivalMessage &&
                          arrivalMessage?.chatId === c.chatId &&
                          arrivalMessage
                        }
                        setLastMessage={setArrivalMessage}
                        otherUser={otherUser}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
