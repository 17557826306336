import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./profilePageInfo.css";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { useTranslation } from "react-i18next";
const ProfilePageInfo = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { english } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutFun = () => {
    console.log("logout");

    dispatch(logout());
    navigate(`/${english ? "en" : "jp"}`);
  };

  return (
    <div className="profile__page__info__profile__wrapper">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <img
          src={user.logo}
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
          }}
        />
        {/* <h5 className="profile__page__info__profile__name">
          {user ? user.name : ""}
        </h5> */}
        <p className="profile__page__info__user__name">{user.first_name}</p>
        <p className="profile__page__info__startup__name">
          {user.organization.name}
        </p>

        <div className="profile__page__info__account__link__box">
          <a
            href="/my/account/edit/basic"
            style={{ display: "flex", gap: "8px", alignItems: "center" }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3734 8.73437C13.4047 8.5 13.4203 8.25781 13.4203 8C13.4203 7.75 13.4047 7.5 13.3656 7.26562L14.9516 6.03125C15.0922 5.92187 15.1313 5.71094 15.0453 5.55469L13.5453 2.96094C13.4516 2.78906 13.2563 2.73437 13.0844 2.78906L11.2172 3.53906C10.8266 3.24219 10.4125 2.99219 9.95156 2.80469L9.67031 0.820312C9.63906 0.632812 9.48281 0.5 9.29531 0.5H6.29531C6.10781 0.5 5.95937 0.632812 5.92812 0.820312L5.64688 2.80469C5.18594 2.99219 4.76406 3.25 4.38125 3.53906L2.51406 2.78906C2.34219 2.72656 2.14688 2.78906 2.05313 2.96094L0.560938 5.55469C0.467188 5.71875 0.498438 5.92187 0.654688 6.03125L2.24063 7.26562C2.20156 7.5 2.17031 7.75781 2.17031 8C2.17031 8.24219 2.18594 8.5 2.225 8.73437L0.639063 9.96875C0.498438 10.0781 0.459375 10.2891 0.545313 10.4453L2.04531 13.0391C2.13906 13.2109 2.33438 13.2656 2.50625 13.2109L4.37344 12.4609C4.76406 12.7578 5.17812 13.0078 5.63906 13.1953L5.92031 15.1797C5.95938 15.3672 6.10781 15.5 6.29531 15.5H9.29531C9.48281 15.5 9.63906 15.3672 9.6625 15.1797L9.94375 13.1953C10.4047 13.0078 10.8266 12.7578 11.2094 12.4609L13.0766 13.2109C13.2484 13.2734 13.4438 13.2109 13.5375 13.0391L15.0375 10.4453C15.1313 10.2734 15.0922 10.0781 14.9438 9.96875L13.3734 8.73437ZM7.79531 10.8125C6.24844 10.8125 4.98281 9.54688 4.98281 8C4.98281 6.45312 6.24844 5.1875 7.79531 5.1875C9.34219 5.1875 10.6078 6.45312 10.6078 8C10.6078 9.54688 9.34219 10.8125 7.79531 10.8125Z"
                fill="#C8C8C8"
              />
            </svg>
            <p className="profile__page__info__account__link__box__account__setting">
              {t("AccountSetting")}
            </p>
          </a>
        </div>
        <div className="profile__page__info__account__link__box__startup__detail">
          <Link
            to={
              user.belongs_to === "STARTUP"
                ? `/${english ? "en" : "jp"}/startup-detail/${
                    user.organization?.id
                  }`
                : `/${english ? "en" : "jp"}/company-detail/${
                    user.organization?.id
                  }`
            }
            style={{ display: "flex", gap: "8px", alignItems: "center" }}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.25 0.25H1.75C0.9175 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.9175 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V1.75C13.75 0.925 13.0825 0.25 12.25 0.25ZM12.25 12.25H1.75V3.25H12.25V12.25ZM10.75 7H3.25V5.5H10.75V7ZM7.75 10H3.25V8.5H7.75V10Z"
                fill="#C8C8C8"
              />
            </svg>
            <p className="profile__page__info__account__link__box__startup__page">
              {user.belongs_to === "STARTUP"
                ? t("MyStartupPage")
                : t("MyCompanyPage")}
            </p>
          </Link>
        </div>
        {user.user_role === "super_user" && (
          <div className="profile__page__info__account__link__box">
            <Link
              to="/en/user-list"
              style={{ display: "flex", gap: "8px", alignItems: "center" }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3734 8.73437C13.4047 8.5 13.4203 8.25781 13.4203 8C13.4203 7.75 13.4047 7.5 13.3656 7.26562L14.9516 6.03125C15.0922 5.92187 15.1313 5.71094 15.0453 5.55469L13.5453 2.96094C13.4516 2.78906 13.2563 2.73437 13.0844 2.78906L11.2172 3.53906C10.8266 3.24219 10.4125 2.99219 9.95156 2.80469L9.67031 0.820312C9.63906 0.632812 9.48281 0.5 9.29531 0.5H6.29531C6.10781 0.5 5.95937 0.632812 5.92812 0.820312L5.64688 2.80469C5.18594 2.99219 4.76406 3.25 4.38125 3.53906L2.51406 2.78906C2.34219 2.72656 2.14688 2.78906 2.05313 2.96094L0.560938 5.55469C0.467188 5.71875 0.498438 5.92187 0.654688 6.03125L2.24063 7.26562C2.20156 7.5 2.17031 7.75781 2.17031 8C2.17031 8.24219 2.18594 8.5 2.225 8.73437L0.639063 9.96875C0.498438 10.0781 0.459375 10.2891 0.545313 10.4453L2.04531 13.0391C2.13906 13.2109 2.33438 13.2656 2.50625 13.2109L4.37344 12.4609C4.76406 12.7578 5.17812 13.0078 5.63906 13.1953L5.92031 15.1797C5.95938 15.3672 6.10781 15.5 6.29531 15.5H9.29531C9.48281 15.5 9.63906 15.3672 9.6625 15.1797L9.94375 13.1953C10.4047 13.0078 10.8266 12.7578 11.2094 12.4609L13.0766 13.2109C13.2484 13.2734 13.4438 13.2109 13.5375 13.0391L15.0375 10.4453C15.1313 10.2734 15.0922 10.0781 14.9438 9.96875L13.3734 8.73437ZM7.79531 10.8125C6.24844 10.8125 4.98281 9.54688 4.98281 8C4.98281 6.45312 6.24844 5.1875 7.79531 5.1875C9.34219 5.1875 10.6078 6.45312 10.6078 8C10.6078 9.54688 9.34219 10.8125 7.79531 10.8125Z"
                  fill="#C8C8C8"
                />
              </svg>

              <p className="profile__page__info__account__link__box__startup__page">
                {t("AllUserList")}
              </p>
            </Link>
          </div>
        )}
      </div>
      <button
        onClick={() => logoutFun()}
        className="profile__page__info__account__link__box__logout"
      >
        {t("logout")}
      </button>
    </div>
  );
};

export default ProfilePageInfo;
