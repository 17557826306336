import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../components/formComponent/FormInput";
import close from "../asset/images/Vector.svg";
import eye from "../asset/images/eye.svg";
import { useTranslation, initReactI18next } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { register, login, reset } from "../features/auth/authSlice";

const SignIn = () => {
  const { t } = useTranslation();
  const [passView, setPassView] = useState(true);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSuccess && user && user.online === "ofline") {
      navigate("/changePass");
    }
    if (isSuccess || user) {
      navigate("/");
    }
    dispatch(reset());
  }, [user, isError, isSuccess, message, dispatch]);

  const inputs = [
    {
      id: 1,
      name: "email",
      type: "text",
      placholder: "Your Email Address",
      label: t("Mail"),
    },
    {
      id: 2,
      name: "password",
      type: passView ? "password" : "text",
      placholder: "password",
      label: t("Password"),
    },
  ];

  const handleSubmit = (e) => {
    console.log(values);
    console.log("hit");
    e.preventDefault();
    dispatch(login(values));
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  console.log(values);
  return (
    <div className="loginBox">
      <form onSubmit={handleSubmit}>
        <div className="v2">
          <Link to="/">
            <img src={close} className="close-login" />
          </Link>
          <h1 className="login-title">{t("SignIn")}</h1>
          {inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
          <div className="hidePass">
            <img src={eye} onClick={() => setPassView(!passView)} />
          </div>
        </div>
        <div className="btnDiv">
          <button className="btn-login">{t("SignIn")}</button>
        </div>
        <p className="p-login">{t("AlreadyAccount")}</p>
      </form>
    </div>
  );
};

export default SignIn;
