import React, { useState } from "react";
import { Link } from "react-router-dom";
import FormInput from "../components/formComponent/FormInput";
import close from "../asset/images/Vector.svg";
import eye from "../asset/images/eye.svg";
import { useTranslation, initReactI18next } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const [passView, setPassView] = useState(true);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const inputs = [
    {
      id: 1,
      name: "email",
      type: "text",
      placholder: "Your Email Address",
      label: "Mail Address",
    },
    {
      id: 2,
      name: "password",
      type: passView ? "password" : "text",
      placholder: "password",
      label: "Password",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  console.log(values);
  return (
    <div className="loginBox">
      <form onSubmit={handleSubmit}>
        <div className="v2">
          <Link to="/">
            <img src={close} className="close-login" />
          </Link>
          <h1 className="login-title">{t("login")} </h1>
          {inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
          <div className="hidePass">
            <img src={eye} onClick={() => setPassView(!passView)} />
          </div>
        </div>
        <div className="btnDiv">
          <button className="btn-login">{t("login")} </button>
        </div>
        <p className="p-login">{t("DontHaveAccount")}</p>
      </form>
    </div>
  );
};

export default Login;
