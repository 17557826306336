import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import languageDetector from "i18next-browser-languagedetector";
// const API_URL = process.env.REACT_APP_API_URL;
const API_URL = "https://tw-dev.creww.me/";
// const API_URL = "http://192.168.19.144:53000/";

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "jp"],
    fallbackLng: "en",
    detection: {
      order: ["path", "htmlTag", "localStorage", "subdomain"],
      lookupFromPathIndex: 0,
    },
    backend: {
      loadPath: `${API_URL}locales/{{lng}}/translation.json`,
    },
    react: { useSuspense: false },
  });

export default i18n;
