import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL;
// const username = process.env.REACT_APP_AUTH_NAME;
// const password = process.env.REACT_APP_AUTH_PASSWORD;
// const API_URL = "http://192.168.72.192:8080/";
const API_URL = "https://tw-dev.creww.me/";
// const API_URL = "http://172.20.10.7:8080/";
// const API_URL2 = "http://172.20.10.7:53000/";
// const API_URL2 = "http://192.168.19.144:53000/";
// const API_URL = "http://192.168.19.144:8080/";

const username = "creww";
const password = "creww";

//User Password Reset
export const passwordReset = async (id, values) => {
  console.log("passwordReset API call");
  return await axios(`${API_URL}account/${id}/update_password`, {
    method: "PUT",
    data: values,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

//Other User info by email
export const otherUserInfo = async (email) => {
  return await axios(`${API_URL}user_info_by_email?email=${email}`, {
    method: "GET",
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

//View All Companies
export const getCompanyList = async (headers) => {
  console.log("getCompanyList API call");
  return await axios(`${API_URL}api/v1/itri/company-list`, {
    method: "GET",
    auth: {
      username: "creww",
      password: "creww",
    },
    headers,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

//View Company Detail
export const getCompanyDetail = async (id, headers) => {
  console.log("getCompanyDetail API call");
  return await axios(`${API_URL}api/v1/itri/company-detail?companyId=${id}`, {
    method: "GET",
    auth: {
      username,
      password,
    },
    headers,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

//View All Users
export const getUsersList = async (headers) => {
  return await axios(
    `${API_URL}api/v1/itri/organization-accounts?userRole=ALL&isRepresentative=true`,
    {
      method: "GET",
      auth: {
        username,
        password,
      },
      headers,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

//View All Startups
export const getStartupList = async () => {
  console.log("getStartupList API call");
  return await axios(`${API_URL}startups/list`, {
    method: "GET",
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

//View  Startup Detail
export const getStartupDetail = async (id, headers) => {
  console.log("getStartupDetail API call");
  return await axios(`${API_URL}startups/${id}/details`, {
    method: "GET",
    headers,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

//Get Application Form
export const getApplicationForm = async (id) => {
  console.log("getApplicationForm API call");
  return await axios(
    `${API_URL}api/v1/itri/company-application-form?companyId=${id}`,
    {
      method: "GET",
      auth: {
        username,
        password,
      },
    }
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

// Translation API
export const postTranslatedText = async (qNo, qText, qAnswer) => {
  console.log("postTranslatedText API call");
  return await axios(
    `${API_URL}api/v1/itri/translate-multiple-text`,
    // "https://tw-dev.creww.me/api/v1/itri/translate-multiple-text",
    {
      method: "POST",
      data: {
        texts: [qNo, qText && qText.text, qAnswer],
        translationProvider: "DEEPL",
        sourceLanguage: "ENGLISH",
        targetLanguage: "JAPANESE",
      },
      auth: {
        username,
        password,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

// Translation Single Text API
export const singleTranslatedText = async (string) => {
  console.log("postTranslatedText API call");
  return await axios(`${API_URL}api/v1/itri/translate-text`, {
    method: "POST",
    data: {
      text: string,
      translationProvider: "DEEPL",
      sourceLanguage: "ENGLISH",
      targetLanguage: "JAPANESE",
    },
    auth: {
      username,
      password,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
// Post Form Data
export const postFormData = async (data) => {
  console.log("postFormData API call");
  return await axios(`${API_URL}api/v1/itri/company-application-form`, {
    method: "POST",
    data,
    auth: {
      username,
      password,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return res;
  });
  // .catch((err) => {
  //   console.log(err);
  // });
};

//Get Notification
export const getNotification = async (userEmail) => {
  // console.log("getNotification API call");
  return await axios(`${API_URL}api/v1/itri/unseen-chat-count/${userEmail}`, {
    method: "GET",
    auth: {
      username,
      password,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error, "notification");
    });
};

//View All Conversations
export const getAllConversations = async (userEmail, headers) => {
  // console.log("getAllConversations API call");
  return await axios(`${API_URL}api/v1/itri/chats/${userEmail}`, {
    method: "GET",
    auth: {
      username,
      password,
    },
    headers,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

//View All Messages
export const getAllMessages = async (chatId, pageNo, headers) => {
  console.log("getAllMessages API call");
  return await axios(
    `${API_URL}api/v1/itri/messages/${chatId}?page=${pageNo}`,
    {
      method: "GET",
      auth: {
        username,
        password,
      },
      headers,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

// Acknoledge API
export const acknowledgeAPI = async (senderEmail, chatId) => {
  console.log("acknowledgeAPI API call");
  return await axios(`${API_URL}api/v1/itri/message-seen-acknowledgement`, {
    method: "POST",
    data: { senderEmail, chatId },
    auth: {
      username,
      password,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return res;
  });
  // .catch((err) => {
  //   console.log(err);
  // });
};

// Post Attachment
export const addAttachment = async (contentFiles) => {
  console.log("addAttachment API call");
  return await axios(
    `${API_URL}api/v1/itri/multiple-content-upload?entity=MESSAGE_CONTENT&entityId=0`,
    {
      method: "POST",
      data: contentFiles,
      auth: {
        username,
        password,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    return res;
  });
  // .catch((err) => {
  //   console.log(err);
  // });
};
