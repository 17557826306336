import React, { useState, useEffect } from "react";
import "../cover/style.css";
import { useTranslation } from "react-i18next";
import coverImgEN from "../../asset/images/1_KV/ITRI_cover_EN.png";
import coverImgJP from "../../asset/images/1_KV/ITRI_cover_JP.png";
import coverImgMobEN from "../../asset/images/1_KV/ITRI_cover_SP_EN.png";
import coverImgMobJP from "../../asset/images/1_KV/ITRI_cover_SP_JP.png";

import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCompanyList, getStartupList } from "../api";
import DotLoader from "../loader/DotLoader";
import { updateCompanyId } from "../../features/companyId/companyIdSlice";
import AndoHazamaTopImage from "../../asset/images/AndoHazama/ITRI_company_top_AndoHazama.png";
import AndoHazamaTeamImage from "../../asset/images/AndoHazama/ITRI_company_team_AndoHazama.png";
import AndoHazamaResourceImage from "../../asset/images/AndoHazama/ITRI_company_resource_AndoHazama.png";
import NissayTopImage from "../../asset/images/Nissay/ITRI_company_top_Nissay.png";
import NissayResourceImage from "../../asset/images/Nissay/ITRI_company_resource_Nissay.png";
import TokyoGasTopImage from "../../asset/images/TokyoGAS/ITRI_company_top_TokyoGAS.png";
import TokyoGasTeamImage from "../../asset/images/TokyoGAS/ITRI_company_team_Nissay.png";
import TokyoGasResourceImage from "../../asset/images/TokyoGAS/ITRI_company_resource_TokyoGAS.png";
import crewwCoverImage from "../../asset/images/crewwCoverImage.png";
const Home2 = () => {
  const { user } = useSelector((state) => state.auth);
  const { english } = useSelector((state) => state.language);
  const headers = { accountLanguage: english ? "EN" : "JA" };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(true);
  const [companyOneAll, setCompanyOneAll] = useState(false);
  const [companyTwoAll, setCompanyTwoAll] = useState(false);
  const [companyThreeAll, setCompanyThreeAll] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(0);

  const [companies, setCompanies] = useState([]);
  const [startups, setStartups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startupLoading, setStartupLoading] = useState(true);
  const [error, setError] = useState(false);
  const [companyId, setCompanyId] = useState(0);

  if (user && user.user_role != "test_user") {
    navigate("/");
  }
  if (user && user.password_changed === false) {
    navigate(`/${english ? "en" : "jp"}/changePass`);
  }

  const initValue = async () => {
    setLoading(true);
    setStartupLoading(true);
    setError(false);
    await getCompanyList(headers)
      .then((res) => {
        setCompanies(res.data.data[3]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });

    await getStartupList()
      .then((res) => {
        setStartups(res.data?.data);
        setStartupLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    initValue();
  }, [english]);

  useEffect(() => {
    dispatch(updateCompanyId(companyId));
  }, [companyId]);
  console.log(companies, "companies");
  return (
    <div className="landingPage">
      <div>
        <picture>
          <source
            media="(max-width: 600px)"
            srcset={english ? coverImgMobEN : coverImgMobJP}
          />

          <img
            className="landingpage__image"
            src={english ? coverImgEN : coverImgJP}
          />
        </picture>
      </div>

      <div className="landingpage__about">
        <p className="landingpage__about__title">ABOUT</p>
        <p className="landingpage__about__description">{t("AboutDetail")}</p>
        <p
          className="landingpage__about__description"
          style={{ margin: "0 auto", display: !showMore ? "" : "none" }}
        >
          {t("AboutDetail2")}
        </p>
        <button
          className="landingpage__about__toggle__btn"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? t("view_more") : t("show_less")}
        </button>
      </div>

      <div className="landingpage__companies">
        <p className="landingpage__companies__title">COMPANIES</p>
        <div>{loading && <DotLoader />}</div>
        {/* company One info ................................................. */}

        <div className="landingpage__company__profile">
          <img
            className="landingpage__company__profile__image"
            // src={AndoHazamaTopImage}
            src={crewwCoverImage}
          />
          <div className="landingpage__company__profile__detail">
            <div className="landingpage__company__profile__detail__left">
              <div className="landingpage__company__profile__detail__left__title">
                {companies && companies.companyName}
              </div>
              <div className="landingpage__company__profile__detail__left__subtitle">
                {/* {t("Realizationofasustainablesociety")} */} Dummy Title
              </div>
              <div className="landingpage__company__profile__detail__left__description">
                {t("Withtheaimof")}
              </div>
              <div className="landingpage__company__profile__detail__left__description__title">
                {/* - {t("Decarbonizationeffortsthroughtheuseof")} */}- Dummy
                Title
              </div>
              <div className="landingpage__company__profile__detail__left__description">
                {t("Hydrogen,ammonia,building")}
              </div>
              {/* <div className="landingpage__company__profile__detail__left__description__title">
                - {t("Energyconservationinitiatives")}
              </div> */}
              <div className="landingpage__company__profile__detail__left__description">
                {t("Netzeroenergybuildings")}
              </div>

              <div
                className="landingpage__company__profile__detail__left__toggle"
                style={{
                  display: companyOneAll ? "" : "none",
                }}
              >
                {/* <div
                  className="landingpage__company__profile__detail__left__description"
                  style={{ padding: "0" }}
                >
                  {t("utilization,promotion")}
                </div> */}
                <div className="landingpage__company__profile__detail__left__description__title">
                  - {t("SmartAgriculture")}
                </div>
                <div className="landingpage__company__profile__detail__left__description">
                  {t("Plantfactories")}
                </div>
                <div className="landingpage__company__profile__detail__left__description__title">
                  - {t("SmartForestry")}
                </div>
                <div className="landingpage__company__profile__detail__left__description">
                  {t("UtilizationofCLT")}
                </div>
                <div className="landingpage__company__profile__detail__left__description__title">
                  - {t("Technologiestomaintainbiodiversity")}
                </div>
                <div className="landingpage__company__profile__detail__left__description">
                  {t("Greeningplans,transplantation")}
                </div>
                <div className="landingpage__company__profile__detail__left__description__title">
                  - {t("Explorationofinnovativesemiconductortechnologies")}
                </div>
                <div className="landingpage__company__profile__detail__left__description">
                  {t("Application to construction")}
                </div>
                <div className="landingpage__company__profile__detail__left__description">
                  *{t("Wewelcomeawide")}
                </div>
                <p className="landingpage__company__profile__detail__left__toggle__title">
                  <hr
                    style={{
                      width: "30px",
                      border: "1px solid",
                      marginRight: "8px",
                    }}
                  />
                  {t("Keybenefits")}
                </p>
                {/* <img
                  src={AndoHazamaResourceImage}
                  style={{ width: "100%" }}
                  className="landingpage__company__profile__key__benefits__image"
                /> */}
                <div className="landingpage__company__profile__detail__left__toggle__section">
                  <p className="landingpage__company__profile__detail__left__toggle__section__title">
                    {t("1.CustomerBase")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__detail">
                    {t("Wehaveconnectionswith")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__detail">
                    * {t("However,there")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__title">
                    {t("2.Networkwithclientcompanies")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__detail">
                    {t("Wehaveanetworkofapproximately")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__title">
                    {t("3.OfficesandEmployees")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__detail">
                    {t("Wehaveanetworkofmorethan")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__title">
                    {t("4.GlobalNetwork")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__detail">
                    {t("Research,investment")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__title">
                    {t("5.Effortsinthe")}
                  </p>
                  <p className="landingpage__company__profile__detail__left__toggle__section__detail">
                    {t("Participationinrenewable")}
                  </p>
                </div>

                <div
                  className="landingpage__company__profile__detail__left__toggle__team"
                  style={{ paddingTop: "0" }}
                >
                  <p className="landingpage__company__profile__detail__left__toggle__team__title">
                    <hr
                      style={{
                        width: "30px",
                        border: "1px solid",
                        marginRight: "8px",
                      }}
                    />{" "}
                    {t("MessagefromTeam")}
                  </p>
                  {/* <div className="landingpage__company__profile__detail__left__toggle__team__box">
                    <img
                      src={AndoHazamaTeamImage}
                      className="landingpage__company__profile__detail__left__toggle__team__box__image"
                    />
                    <p className="landingpage__company__profile__detail__left__toggle__team__box__detail">
                      {t("Inpursuitofourphilosophyof")}
                      <br />
                      {t("Inpursuitofourphilosophyof2")}
                    </p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="landingpage__company__profile__detail__right">
              {user ? (
                <Link to={`/${english ? "en" : "jp"}/form/4`}>
                  <button
                    onClick={() => setCompanyId(4)}
                    className="landingpage__company__profile__detail__right__entry__btn"
                  >
                    {t("ENTRY")}
                  </button>
                </Link>
              ) : (
                <a href="/login" onClick={() => setCompanyId(4)}>
                  <button className="landingpage__company__profile__detail__right__entry__btn">
                    {t("ENTRY")}
                  </button>
                </a>
              )}
              <Link to={`/${english ? "en" : "jp"}/company-detail/4`}>
                {" "}
                <button className="landingpage__company__profile__detail__right__about__us__btn">
                  {t("Companydetails")}
                </button>
              </Link>
              <button
                className="landingpage__company__profile__detail__right__key__benefit__btn"
                onClick={() => {
                  setCompanyOneAll(!companyOneAll);
                  setSelectedCompany(4);
                }}
              >
                {t("KeyBenefit&")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="landingpage__startups__list">
        <p className="landingpage__startups__title">STARTUPS</p>
        <div className="landing__page__startups__logos">
          {startups.length ? (
            startups.map((startup, i) => (
              <>
                {startup.id === 52 ? (
                  <Link
                    to={`/${english ? "en" : "jp"}/startup-detail/${
                      startup.id
                    }`}
                  >
                    <img
                      src={startup.logo.url}
                      className="landing__page__startup__logo"
                      key={i}
                    />
                  </Link>
                ) : (
                  ""
                )}
              </>
            ))
          ) : (
            <DotLoader />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home2;
