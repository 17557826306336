import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getApplicationForm,
  postTranslatedText,
  postFormData,
} from "../api/index";
import "./entryForm.css";
import Modal from "../modal/Modal";
import emoji from "../../asset/images/emoji.png";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Loader from "../loader/Loader";
import "../loader/loader.css";
import logo from "../../asset/images/comlogo.png";
const EntryForm = () => {
  const { user } = useSelector((state) => state.auth);
  const { english } = useSelector((state) => state.language);

  if (!user) {
    window.location.replace("/login");
  }
  const { id } = useParams();
  const [file, setFile] = useState();
  const [values, setValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [achieve, setAchieve] = useState("");
  const [company, setCompany] = useState([]);
  const [question, setQuestion] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [success, setSuccess] = useState(false);
  const [translatedQA, setTranslatedQA] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  if (user && user.user_role != "test_user" && id === 4) {
    // window.location.href ===
    //   `https://tw-dev.creww.me/${english ? "en" : "jp"}/form/5`)
    navigate(`/${english ? "en" : "jp"}`);
  }
  if (user && user.user_role === "test_user" && id != 4) {
    navigate(`/${english ? "en" : "jp"}`);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (user && user.password_changed === false) {
    navigate(`/${english ? "en" : "jp"}/changePass`);
  }
  const initValue = async () => {
    getApplicationForm(id)
      .then((res) => {
        setCompany(res.data.data);
        console.log(res.data.data, "company");
        setQuestion(res.data.data.questions);
      })
      .then((res) => setQuestion(res))
      .catch((err) => console.log(err));
  };

  //translate AP
  const translateAPI = async (questionNo, answer) => {
    setLoading(true);
    let questionText =
      company && company.questions.find((x) => x.id === parseInt(questionNo));
    console.log(questionText, "company Q");
    return postTranslatedText(questionNo, questionText, answer)
      .then((res) => {
        return res.data.data.translatedTexts;
      })
      .catch((err) => console.log(err, "from translate api"));
  };

  //POST Form Data
  const sendForm = async () => {
    setLoading(true);
    postFormData(formData)
      .then(function (response) {
        setSuccess(response.data.success);
        console.log(translatedQA, "QA-001");
        response.status === 200 && setLoading(false);
        setFile("");
        setIsOpen(true);
      })
      .catch(function (response) {
        console.log(response);
        response.status != 200 && setLoading(false);
        setIsOpen(true);
      });
  };
  const formData = new FormData();

  //On Submit
  const onSubmit = async (e) => {
    setLoading(true);
    const array = Object.entries(e);
    let q0 = array[0];
    let q1 = array[1];
    let q2 = array[2];
    let q3 = array[3];

    const answers = [
      [q0 && q0[0], q0 && q0[1]],
      [q1 && q1[0], q1 && q1[1]],
      [q2 && q2[0], q2 && q2[1]],
      [q3 && q3[0], q3 && q3[1]],
    ];
    let questionText1 = company && company.questions.find((x) => x.id == q0[0]);
    let questionText2 = company && company.questions.find((x) => x.id == q1[0]);
    let questionText3 = company && company.questions.find((x) => x.id == q2[0]);
    let questionText4 = company && company.questions.find((x) => x.id == q3[0]);

    let translatedPayload = {};

    let promiseExecution = async () => {
      let translateAnswer = await Promise.all(
        answers.map((x) => translateAPI(x[0], x[1]))
      );

      console.log(translateAnswer[0], "promise");
      let t0 = translateAnswer[0];
      let t1 = translateAnswer[1];
      let t2 = translateAnswer[2];
      let t3 = translateAnswer[3];

      translatedPayload = {
        data: [
          { id: t0 && t0[0], questionText: t0 && t0[1], answer: t0 && t0[2] },
          { id: t1 && t1[0], questionText: t1 && t1[1], answer: t1 && t1[2] },
          { id: t2 && t2[0], questionText: t2 && t2[1], answer: t2 && t2[2] },
          { id: t3 && t3[0], questionText: t3 && t3[1], answer: t3 && t3[2] },
        ],
        startupName: user.organization.name,
        startupId: user.organization.id,
        startupLogo: user.organizationLogo,
        startupUrl: user.organization.url,
        startupUser: user.first_name + " " + user.last_name,
      };
      console.log(translatedPayload, "translatedPayload");
      formData.append("translatedText", JSON.stringify(translatedPayload));
    };

    const originalPayload = {
      data: [
        {
          id: q0 && q0[0],
          questionText: questionText1?.text || "what ?",
          answer: q0 && q0[1],
        },
        {
          id: q1 && q1[0],
          questionText: questionText2?.text || "what ?",
          answer: q1 && q1[1],
        },
        {
          id: q2 && q2[0],
          questionText: questionText3?.text || "what ?",
          answer: q2 && q2[1],
        },
        {
          id: q3 && q3[0],
          questionText: questionText4?.text || "what ?",
          answer: q3 && q3[1],
        },
      ],
      // companyUrl: company.companyUrl,
      companyLogo: company.companyLogoUrl,
      companyId: id,
      startupName: user.organization.name,
      companyName: company.formTitle,
      achieve,
    };
    setValues(e);
    let files = [file && file.size >= 10485760 ? "" : file];
    files.forEach((tag) => formData.append("files", tag));
    formData.append("senderEmail", user.email);
    formData.append("startupId", user.organization.id);
    formData.append("companyId", id);
    formData.append("advantageOfYourService", achieve);
    formData.append("originalText", JSON.stringify(originalPayload));
    formData.append("status", "ACTIVE");
    answers.forEach((answer) => {
      formData.append("answers", answer);
    });

    // Function call
    promiseExecution().then(async () => {
      await sendForm();
    });
  };

  useEffect(() => {
    console.log(translatedQA, "effect");
  }, [setTranslatedQA]);
  console.log("Company Info : ", company);
  console.log("Question : ", question);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  useEffect(() => {
    initValue();
  }, []);

  // console.log(company, "company");
  console.log(file, "file");
  return (
    <div className="entry__form">
      {loading ? (
        <div className="loading-spinner"></div>
      ) : (
        <div className="loading-spinner" style={{ display: "none" }}></div>
      )}
      <h1 className="entry__form__title">ENTRY</h1>
      <img className="entry__form__image" src={company.companyLogoUrl} />
      <div className="entry__form_company__title">
        <p>{company.formTitle}</p>
      </div>

      <form className="entry__form__field" onSubmit={handleSubmit(onSubmit)}>
        <div
          className="entry__form__field__fill__your__proposal"
          style={{ marginTop: "100px" }}
        >
          Please fill your proposal
        </div>
        {company &&
          company.questions?.map((q) => (
            <div className="entry__form__field__one">
              <label className="entry__form__field__label">{q.text}</label>
              <textarea
                className="entry__form__field__text__area"
                name={q.id.toString()}
                ref={register}
                maxLength={500}
              ></textarea>
            </div>
          ))}

        <hr
          className="entry__form__underline"
          style={{ marginTop: "70px", color: "#C8C8C8" }}
        />
        <div
          className="entry__form__field__fill__your__proposal"
          style={{ marginTop: "100px" }}
        >
          Please Tell us about your company
        </div>
        <div className="entry__form__field__one">
          <label className="entry__form__field__label">
            Strengths of your business/ service/ company
          </label>
          <textarea
            value={achieve}
            className="entry__form__field__text__area"
            onChange={(e) => setAchieve(e.target.value)}
            maxLength={500}
          ></textarea>
        </div>
        <div className="entry__form__field__one">
          <label className="entry__form__field__label">
            Company Information & Proposal Document
          </label>
          <div className="entry__form__file">
            <label for="input-file" className="entry__form__file__label">
              <i className="material-icons">
                <svg
                  className="entry__form__input__file__svg"
                  width="20"
                  height="26"
                  viewBox="0 0 20 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 0.5H2.5C1.125 0.5 0.0125003 1.625 0.0125003 3L0 23C0 24.375 1.1125 25.5 2.4875 25.5H17.5C18.875 25.5 20 24.375 20 23V8L12.5 0.5ZM17.5 23H2.5V3H11.25V9.25H17.5V23ZM5 16.7625L6.7625 18.525L8.75 16.55V21.75H11.25V16.55L13.2375 18.5375L15 16.7625L10.0125 11.75L5 16.7625Z"
                    fill="black"
                  />
                </svg>
              </i>
              {file ? (
                <p
                  style={{
                    color: "green",
                    paddingTop: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  {file && file.size >= 10485760
                    ? "Upload your file"
                    : "Attachment Added"}
                </p>
              ) : (
                "Upload your file"
              )}
            </label>
            <input id="input-file" type="file" onChange={handleChange} />
          </div>
          <p style={{ color: "red", paddingTop: "10px" }}>
            {file && file.size >= 10485760
              ? " ** Your file size is too big. Please add max 10Mb size file."
              : ""}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "80px 0 123px 0",
          }}
        >
          {user.belongs_to === "STARTUP" ? (
            <button className="entry__form__submit__btn" onClick={handleSubmit}>
              Submit
            </button>
          ) : (
            ""
          )}
        </div>
      </form>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <>
          {success ? (
            <>
              <div className="modal__send__symbol">
                <img src={emoji} />
                <p className="modal__greeting">Thank you!</p>
                <p className="modal__send__message">
                  Your proposal has been send
                </p>
                <p className="modal__send__message">
                  Please check your mailbox
                </p>
              </div>
              <Link to={`/${english ? "en" : "jp"}/chat`}>
                <input
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="modal__end__btn"
                  value="See your proposal copy"
                />
              </Link>{" "}
            </>
          ) : (
            <>
              <p className="modal__greeting" style={{ textAlign: "center" }}>
                Server Error ...
              </p>
              <input
                type="button"
                onClick={() => setIsOpen(false)}
                className="modal__end__btn"
                value="Try Again"
              />
            </>
          )}
        </>
      </Modal>
    </div>
  );
};

export default EntryForm;
