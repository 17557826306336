import React, { Component, Fragment } from "react";
import { Routes, Route, Redirect, Switch, Navigate } from "react-router-dom";
import Homes from "./cover/index2";
import Homes2 from "./cover2/index2";
import Login from "./Login";
import SignIn from "./SignIn";
import ChangePass from "./ChangePassword";
import Navbar from "./navbar/index.js";
import Setting from "./AccountSetting";
import Footer from "./footer";
import { hot } from "react-hot-loader/root";
import Profile from "./profile/ProfilePage";
import Profile2 from "./profile2/ProfilePage";
import CompanyDetail from "../components/company/CompanyDetail";
import EntryForm from "../components/form/EntryForm";
import StarupPage from "../components/startup/StartupDetail";
import RedirectComponent from "./RedirectComponent";
import ResetPassword from "./reset";
import SiteMap from "./sitemap";
import "./App.css";
import ListUser from "./userList/userList";
const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/:locale" element={<Homes />} />
        <Route path="/:locale/stageserver" element={<Homes2 />} />
        <Route path="/" element={<Navigate to="/en" />} />
        <Route path="/:locale/chat" element={<Profile />} />
        <Route path="/:locale/chat/:otherEmail" element={<Profile2 />} />
        <Route path="/:locale/company-detail/:id" element={<CompanyDetail />} />
        <Route path="/:locale/form/:id" element={<EntryForm />} />
        <Route path="/:locale/startup-detail/:id" element={<StarupPage />} />
        <Route path="/:locale/user-list" element={<ListUser />} />
        {/* <Route path="/ja/setting" element={<Setting />} /> */}
        <Route path="/:locale/changePass" element={<ChangePass />} />
        {/* <Route path="/ja/login" element={<Login />} /> */}
        {/* <Route path="/ja/signin" element={<SignIn />} /> */}
        <Route path="/test" element={<RedirectComponent />} />
        {/* <Route path="/ja/reset" element={<ResetPassword />} /> */}
        <Route path="/:locale/sitemap" element={<SiteMap />} />
      </Routes>
      <Footer />
    </>
  );
};

export default hot(App);
