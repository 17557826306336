import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./style.css";
import logo from "../../asset/images/logo.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { changeLan } from "../../features/language/languageSlice";
import { toggle } from "../../features/hamToggle/hamSlice";
import { getNotification } from "../api";
import { getNotificationCount } from "../../features/notification/notificationSlice";
const API_URL = "https://tw-dev.creww.me/";

const Navbar = () => {
  const { english } = useSelector((state) => state.language);
  const { t } = useTranslation();
  const [lang, setLang] = useState(!english);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { count } = useSelector((state) => state.notification);
  const navigate = useNavigate();
  const { isEnable } = useSelector((state) => state.ham);

  useEffect(() => {
    const timer = setInterval(() => {
      getNotification(user.email).then(() => {
        dispatch(getNotificationCount(user.email));
      });
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <nav>
        <div className="nav__left">
          {/* {window.location.href ===
          `http://172.20.10.7:53000/${english ? "en" : "jp"}/chat` ? ( */}
          {window.location.href ===
          `${API_URL}${english ? "en" : "jp"}/chat` ? (
            <span className="navbar__ham" onClick={() => dispatch(toggle())}>
              {!isEnable ? (
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 12H17C17.55 12 18 11.55 18 11C18 10.45 17.55 10 17 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12ZM1 7H17C17.55 7 18 6.55 18 6C18 5.45 17.55 5 17 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7ZM0 1C0 1.55 0.45 2 1 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H1C0.45 0 0 0.45 0 1Z"
                    fill="#838383"
                  />
                </svg>
              ) : (
                <svg
                  className="navbar__toggle__close"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.8215 0.837541C21.1715 0.187541 20.1215 0.187541 19.4715 0.837541L11.3215 8.97087L3.17148 0.820874C2.52148 0.170874 1.47148 0.170874 0.821484 0.820874C0.171484 1.47087 0.171484 2.52087 0.821484 3.17087L8.97149 11.3209L0.821484 19.4709C0.171484 20.1209 0.171484 21.1709 0.821484 21.8209C1.47148 22.4709 2.52148 22.4709 3.17148 21.8209L11.3215 13.6709L19.4715 21.8209C20.1215 22.4709 21.1715 22.4709 21.8215 21.8209C22.4715 21.1709 22.4715 20.1209 21.8215 19.4709L13.6715 11.3209L21.8215 3.17087C22.4548 2.53754 22.4548 1.47087 21.8215 0.837541Z"
                    fill="#C8C8C8"
                  />
                </svg>
              )}{" "}
            </span>
          ) : (
            ""
          )}
          <Link to={`/${english ? "en" : "jp"}`}>
            <img src={logo} alt="logo" className="nav__logo" />
          </Link>
        </div>
        <div className="nav__right">
          <ul className="nav__right__links">
            <li onClick={() => setLang(!lang)}>
              {lang ? (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    dispatch(changeLan());
                    i18next.changeLanguage("en");
                    // window.location.replace("/en");
                    let value = window.location.href.split("/").slice(4);
                    navigate(
                      value.length === 1
                        ? "/en/" + value[0]
                        : value.length === 2
                        ? "/en/" + value[0] + "/" + value[1]
                        : "/en"
                    );
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.35 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM6.03 6H3.08C4.04 4.34 5.57 3.07 7.41 2.44C6.81 3.55 6.35 4.75 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z"
                      fill="#838383"
                    />
                  </svg>
                  <a>EN</a>
                </div>
              ) : (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    dispatch(changeLan());
                    i18next.changeLanguage("jp");
                    // window.location.replace("/jp");
                    // window.location.href.split("/")[3];
                    let value = window.location.href.split("/").slice(4);
                    navigate(
                      value.length === 1
                        ? "/jp/" + value[0]
                        : value.length === 2
                        ? "/jp/" + value[0] + "/" + value[1]
                        : "/jp"
                    );
                    setLang(!lang);
                  }}
                >
                  {" "}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.35 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM6.03 6H3.08C4.04 4.34 5.57 3.07 7.41 2.44C6.81 3.55 6.35 4.75 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z"
                      fill="#838383"
                    />
                  </svg>
                  <a>日本語</a>
                </div>
              )}
            </li>
            {user ? (
              <li>
                <Link to={`/${english ? "en" : "jp"}/chat`}>
                  <img
                    src={user?.logo}
                    className="nav__right__links__svg"
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                    }}
                  />
                  {count > 0 ? (
                    <span className="nav__right__links__notification">
                      {count}
                    </span>
                  ) : (
                    ""
                  )}
                </Link>
              </li>
            ) : (
              <li>
                <a href="/login" style={{ display: "flex" }}>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM11 3.3C12.826 3.3 14.3 4.774 14.3 6.6C14.3 8.426 12.826 9.9 11 9.9C9.174 9.9 7.7 8.426 7.7 6.6C7.7 4.774 9.174 3.3 11 3.3ZM11 18.92C8.25 18.92 5.819 17.512 4.4 15.378C4.433 13.189 8.8 11.99 11 11.99C13.189 11.99 17.567 13.189 17.6 15.378C16.181 17.512 13.75 18.92 11 18.92Z"
                      fill="#838383"
                    />
                  </svg>
                  <a
                    className="nav__right__links__name"
                    style={{ alignSelf: "center" }}
                  >
                    {t("login")}
                  </a>
                </a>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
