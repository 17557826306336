import axios from "axios";

import { getNotification } from "../../components/api";
//Get Notification
const getNotificationCount = async (userEmail) => {
  const response = await getNotification(userEmail);

  return response.data.data;
};

const notificationService = {
  getNotificationCount,
};

export default notificationService;
