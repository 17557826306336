import React from "react";
import "./style.css";

export default function DotLoader() {
  return (
    <div className="regot-loader">
      <div className="regot regot1"></div>
      <div className="regot regot2"></div>
      <div className="regot regot3"></div>
      <div className="regot regot4"></div>
    </div>
  );
}
